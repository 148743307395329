import { Auth } from "aws-amplify";
import React, { Component } from "react";


export default class LeftNav extends Component {
	constructor(props) {
		super(props);

		this.state = {
		  isLoading: true,
		  notes: []
		};
	}
  
    handleLogout = async event => {
	  await Auth.signOut();
	  this.setState({ userHasAuthenticated:false });
	  this.props.history.push("/signin");
	}
  
	async componentDidMount() {
	  try {
		await Auth.currentSession();
		this.setState({ userHasAuthenticated: true });
	  }
	  catch(e) {
		if (e !== 'No current user') {
		  alert(e);
		}
	  }

	  this.setState({ isAuthenticating: false });
	}
	



render(){

	return (
		<div className="fixed-sidebar-left">
			<ul className="nav navbar-nav side-nav nicescroll-bar">
					<li><hr className="light-grey-hr mb-10"/></li>
					<li className="navigation-header">
						<span>Reporting</span> 
						<i className="zmdi zmdi-more"></i>
					</li>
					<li>
						<a href="/a/dashboard"><div className="pull-left"><i className="zmdi zmdi-landscape mr-20"></i><span className="right-nav-text">dMARC dashboard</span></div><div className="clearfix"></div></a>
					</li>
					<li>
						<a href="/a/dashboard/381f557e-b039-4193-828b-d2a44280b594"><div className="pull-left"><i className="zmdi zmdi-chart mr-20"></i><span className="right-nav-text">sPF dashboard</span></div><div className="clearfix"></div></a>
					</li>
					<li>
						<a href="/a/dashboard/661dd1e7-32ba-4c61-851f-92e99b1f6a47"><div className="pull-left"><i className="zmdi zmdi-compass mr-20"></i><span className="right-nav-text">Sender Analysis</span></div><div className="clearfix"></div></a>
					</li>
				
				<li><hr className="light-grey-hr mb-10"/></li>
				<li className="navigation-header">
					<span>Configuration</span> 
					<i className="zmdi zmdi-more"></i>
				</li>
				
					
				{/*<li>
					<a href="/dashboard"><div className="pull-left"><i className="zmdi zmdi-shield-check mr-20"></i><span className="right-nav-text">Risk Advisor</span></div><div className="clearfix"></div></a>
				</li>
				<li>
					<a href="/quicksight"><div className="pull-left"><i className="zmdi zmdi-chart mr-20"></i><span className="right-nav-text">Reporting</span></div><div className="clearfix"></div></a>
                </li>*/}
				
				<li>
					<a href="/a/policymanager"><div className="pull-left"><i className="zmdi zmdi-edit mr-20"></i><span className="right-nav-text">Policy Manager</span></div><div className="clearfix"></div></a>
				</li>
				
				
				<li><hr className="light-grey-hr mb-10"/></li>
				<li className="navigation-header">
					<span>Tools</span> 
					<i className="zmdi zmdi-more"></i>
				</li>
				<li>
					<a href="/a/spfinspector"><div className="pull-left"><i className="zmdi zmdi-shield-check mr-20"></i><span className="right-nav-text">Domain Inspector</span></div><div className="clearfix"></div></a>
				</li>
                {/* <li>
					<a href="javascript:void(0);"><div className="pull-left"><i className="zmdi zmdi-edit mr-20"></i><span className="right-nav-text">DKIM Validator</span></div><div className="clearfix"></div></a>
				</li>
                <li>
					<a href="javascript:void(0);"><div className="pull-left"><i className="zmdi zmdi-edit mr-20"></i><span className="right-nav-text">DMARC Builder</span></div><div className="clearfix"></div></a>
				</li>
				<li><hr className="light-grey-hr mb-10"/></li>
				<li className="navigation-header">
					<span>account management</span> 
					<i className="zmdi zmdi-more"></i>
				</li>
                <li>
					<a href="javascript:void(0);" data-toggle="collapse" data-target="#app_dr"><div className="pull-left"><i className="zmdi zmdi-accounts-alt mr-20"></i><span className="right-nav-text">Access Control</span></div><div className="pull-right"><i className="zmdi zmdi-caret-down"></i></div><div className="clearfix"></div></a>
					<ul id="app_dr" className="collapse collapse-level-1">
						<li>
							<a href="/">Account Policies</a>
						</li>
						<li>
							<a href="/">User Roles</a>
						</li>
						<li>
							<a href="/">Identity Federation</a>
						</li>
						<li>
							<a href="/">API Keys</a>
						</li>
					</ul>
				</li>
				
				<li>
					<a className="active" href="javascript:void(0);"><div className="pull-left"><i className="zmdi zmdi-google-pages mr-20"></i><span className="right-nav-text">Billing</span></div><div className="clearfix"></div></a>
				</li>
				<li>
					<a href="documentation.html"><div className="pull-left"><i className="zmdi zmdi-book mr-20"></i><span className="right-nav-text">Support</span></div><div className="clearfix"></div></a>
				</li>
				<li>
					<a href="javascript:void(0);"><div className="pull-left"><i className="zmdi zmdi-filter-list mr-20"></i><span className="right-nav-text">FAQ</span></div><div className="clearfix"></div></a>
				</li>
                */}
			</ul>
		</div>
		);

}


}