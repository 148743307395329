import React, { Component} from "react";
import BaseComponent from "../classes/BaseComponent"
import "../dist/css/style.css";

export default class error extends BaseComponent {
  constructor(props) {
    super(props);
    
    this.pageaudience="public"
    
	/*this.state = {
      email: "",
      password: "",
	  isLoading: false,
	  token: ""
    };*/

  }
  

  render() {
    return (
		<div  >
			<div class="pa-0 ma-0 auth-page">
				<div class="container-fluid">
					
					<div class="table-struct full-width full-height">
						<div class="table-cell vertical-align-middle auth-form-wrap">
							<div class="auth-form  ml-auto mr-auto no-float">
								<div class="row">
									<div class="col-sm-12 col-xs-12">
                                    ERROR
										{ this.state.err }
									</div>	
								</div>
							</div>
						</div>
					</div>	
				</div>
			</div>
		</div>
    );
  }
}