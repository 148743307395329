export default {
    
  s3: {
    REGION: "us-east-1",
    BUCKET: ""
  },
  apiGateway: {
    REGION: "us-east-1",
    URL: " https://api.spfxio.com/staging"
  },
  wsendpoint: "wss://uat.ws.spfxio.com/staging",
  cognito: {
    REGION: "us-east-1",
    USER_POOL_ID: "us-east-1_N9XknslwZ",
    APP_CLIENT_ID: "513a2rrkok318n0cm3mar8ue0k",
    IDENTITY_POOL_ID: "us-east-1:af2d860b-fd75-4f20-b1d9-1f2f96dc1205"
  },
  spfx:{
	  servicedomain : "spfx.io"
  }
};

//https://api.spfxio.com/prod