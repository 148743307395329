/** @class */

import { Auth, API } from "aws-amplify";

export default class spfxdns {

  /**
   * Constructs a new spfxdns object
   * @param {object} config spfxdns config options 
   */
  constructor(config) {
      
    var localConfig = (config)?config:{
             defaultTTL: (20*60) * 1000 //default 20 minutes set in millisconds
            ,itemMaxSize: 3000 // 3000 bytes
             };
  }

  /**
   * This is used to verify a valid spf record
   * @param {string} dnsname - Domain name to be checked
   */
  async verifySpf(dnsname) {

    var result = {
                isvalidformat: (this.isvalidformat(dnsname) &&
                true)
           , isvalidrecord: true
           , isvalidspf: true
           , domaintest : {
                   pid :"df53a80fe90c67b6d3c1ea68980be837"
                  , epoch:1550608545242
                  , level:"info"
                  , datestamp:"2019-02-19T20:35:45.242Z"
                  , message:"_spf.google.com"
                  , objecttype:"object"
                  , obj :{errors:[
                            []
                            ,{errno:"ENOTFOUND"
                            ,code:"ENOTFOUND"
                            ,syscall:"queryTxt"
                            ,hostname:"_dmarc._spf.google.com"}]
                         ,spf:{records:[
                             {spfrecord:"v=spf1 include:_netblocks.google.com include:_netblocks2.google.com include:_netblocks3.google.com ~all"
                             , defaultaction:"softfail"
                             , hostname :"_spf.google.com"}]
                         ,recordcount:1
                         ,defaultaction:"softfail"
                         ,numlookups:3
                         ,numip4:322778}
                         ,dmarc:{action:"",fo:"",rua:"",ruf:""}
                        }     
           }
        }

        result.valid = (result.isvalidformat
                            &&result.isvalidrecord
                            &&result.isvalidspf);

    return(result)

  }

  async verifyDomain(dnsname,getsuggested){

      var result = {
            isvalidformat: (this.isvalidformat(dnsname))
           , isvalidrecord: true
           , isvalidspf: true
        }

     
      if(result.isvalidformat){
          result.spfresult  = await this.fetchspf(dnsname);
          
          return result;
      }else{
          return result;
      }
  }

  
  fetchspf(dnsname){
	  
	  return API.put('getspfrecord', '/getspfrecord', { body:{ "dnsname" : dnsname},headers:{}});
	  
  }

  

 



  async verifyDomain1(dnsname,getsuggested){
    var result = {
                isvalidformat: (this.isvalidformat(dnsname) &&
                true)
           , isvalidrecord: true
           , isvalidspf: true
        }

        result.valid = (result.isvalidformat
                            &&result.isvalidrecord)

        if(getsuggested&&result.isvalidrecord){
                //recommend SPF record for domain
                //if only one include, recommend the one include.
                //if more than one include, recommend the record itself

                result.recommendation = {
                    includes : ['_spf.google.com']
                }

        }
            
                            

    return(result)
  }

  

  isvalidformat(domain) { 
    var re = new RegExp(/^((?:(?:(?:\w[\.\-\+]?)*)\w)+)((?:(?:(?:\w[\.\-\+]?){0,62})\w)+)\.(\w{2,6})$/); 
    if(domain.match(re)){
    	return true;
    }else{
    	return false;
    }
  } 



}