import { Auth } from "aws-amplify";
import React, { Component } from "react";

export default class Footer extends Component {
	constructor(props) {
		super(props);

		this.state = {
		  isLoading: true,
		  notes: []
		};
	}
  
    handleLogout = async event => {
	  await Auth.signOut();
	  this.setState({ userHasAuthenticated:false });
	  this.props.history.push("/signin");
	}
  
	async componentDidMount() {
	  try {
		await Auth.currentSession();
		this.setState({ userHasAuthenticated: true });
	  }
	  catch(e) {
		if (e !== 'No current user') {
		  alert(e);
		}
	  }

	  this.setState({ isAuthenticating: false });
	}
	



render(){

	return (
		
		
		<footer className="footer container-fluid pl-30 pr-30">
			<div className="row">
				<div className="col-sm-12">
					<p> { (new Date()).getFullYear() } &copy;DiMichele Cyber Strategies, Inc.</p>
				</div>
			</div>
		</footer>
		
		
		
		);

}


}