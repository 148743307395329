import { Auth } from "aws-amplify";
import React, { Component, Fragment } from "react";
import {
  HelpBlock,
  FormGroup,
  FormControl,
  ControlLabel,
  Label
} from "react-bootstrap";
import { Input } from 'reactstrap';
import LoaderButton from "../components/LoaderButton"
import BaseComponent from "../classes/BaseComponent"

import NavBar from "./NavBar";
import "../css/style.css";
import "./Signup.css";

export default class Signup extends BaseComponent {
  constructor(props) {
    super(props);
    this.pageaudience="public";
    
    this.state = {
      isLoading: false
	  , name: ""
      , email: ""
      , password: ""
      , confirmPassword: ""
      , confirmationCode: ""
	  , terms: ""
      , newUser: null
      , validform:false
      , errmsg: ''
      , confirmLoadingText: 'Verifying'

    };

      this.oncomponentmounted = async (susr)=> {
        
    };


  }

  validateForm() {
   

    return (
	  this.state.name.length > 0 &&
      this.validateEmail(this.state.email) &&
	  this.state.terms.length > 0 &&
      this.state.password.length > 0 &&
      this.state.password === this.state.confirmPassword
    );
  }

   validateEmail(email) {
        var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(String(email).toLowerCase());
    }

  validateConfirmationForm() {
    return this.state.confirmationCode.length > 0;
  }

  handleChange = async event => {

    await this.setState({
      [event.target.id]: event.target.value
    });

    if(this.validateForm()){
       
        this.setState({
            validform:true
        })
    }
  }

  handleSubmit = async event => {
    event.preventDefault();
    this.setState({ isLoading: true });
	var error = '';
    try {
		const newUser = await Auth.signUp({
		  username: this.state.email,
		  password: this.state.password,
		  attributes: {
			name: this.state.name  
		  }
		  
		});
		this.setState({
		  newUser
		});
	  } catch (e) {
          switch(e.code){
              case "UsernameExistsException":
                error = 'Error encountered.  Verify your details and try again.  If you already have an account, but have forgotten the password, use the forgot password link.';
                break;
              case "InvalidParameterException":
                error = 'Please choose a stronger password.';
                break;
              default:
                error = JSON.stringify(e);
                break;
          }
          
	  }

    this.setState({ errmsg:error, isLoading: false });
  }

  handleConfirmationSubmit = async event => {
    event.preventDefault();

    this.setState({ isLoading: true });
	 try {
		await Auth.confirmSignUp(this.state.email, this.state.confirmationCode);
        this.setState({confirmLoadingText: "Initiating Sign in"});
        await Auth.signIn(this.state.email, this.state.password);
        Auth._storage.setItem("ActiveSession",true,true);
        this.setState({ isLoading: false });
        this.props.history.push("/dashboard");

        
        
        
	  } catch (e) {
		this.setState({ errmsg:e,isLoading: false });
	  }
	  
  }

  renderConfirmationForm() {
    return (
      <form onSubmit={this.handleConfirmationSubmit}>
        <FormGroup controlId="confirmationCode" bsSize="large">
          <ControlLabel>Confirmation Code</ControlLabel>
          <FormControl
            autoFocus
            type="tel"
            value={this.state.confirmationCode}
            onChange={this.handleChange}
          />
          <HelpBlock>Please check your email for the code.</HelpBlock>
        </FormGroup>
        <LoaderButton
          block
          bsSize="large"
          disabled={!this.validateConfirmationForm()}
          type="submit"
          isLoading={this.state.isLoading}
          text="Verify"
          loadingText={this.state.confirmLoadingText}
        />
      </form>
    );
  }

  renderForm() {
    return (
	
	<fragment>
	<div class="mb-30">
		<h3 class="text-center txt-dark mb-10">Sign Up</h3>
		<h6 className={(this.state.errmsg!=='')?'text-center nonecase-font txt-greytext-warning text-warning':'text-center nonecase-font txt-greytext-warning.'}>{(this.state.errmsg!=='')?this.state.errmsg:'Enter your details below to create an account.'}</h6>
	</div>
	<div class="form-wrap">
      <form onSubmit={this.handleSubmit}>
	     <FormGroup controlId="name" bsSize="large"  class="form-group">
          <ControlLabel className="control-label mb-10 font-16">Full Name</ControlLabel>
          <FormControl
            autoFocus
            type="string"
            value={this.state.name}
            id="name"
            onChange={this.handleChange}
			className="form-control txt-primary block mb-10 pull-right font-16"
          />
        </FormGroup>
        <FormGroup controlId="email" bsSize="large">
          <ControlLabel className="control-label mb-10 font-16">Email</ControlLabel>
          <FormControl
            type="email"
            value={this.state.email}
            id="email"
            onChange={this.handleChange}
			className="form-control txt-primary block mb-10 pull-right font-16"
          />
        </FormGroup>
        <FormGroup controlId="password" bsSize="large">
          <ControlLabel className="control-label mb-10 font-16">Password</ControlLabel>
          <FormControl
            value={this.state.password}
            onChange={this.handleChange}
            id="password"
            type="password"
			className="form-control txt-primary block mb-10 pull-right font-16"
          />
        </FormGroup>
        <FormGroup controlId="confirmPassword" bsSize="large">
          <ControlLabel className="control-label mb-10 font-16">Confirm Password</ControlLabel>
          <FormControl
            value={this.state.confirmPassword}
            onChange={this.handleChange}
            type="password"
            id="confirmPassword"
			className="form-control txt-primary block mb-10 pull-right font-16"
          />
        </FormGroup>

		<FormGroup controlId="terms" className="form-group">
				<div class="checkbox-inline checkbox-primary pr-10 pull-left">
					<input id="terms" required="" value="accept" type="checkbox" onChange={this.handleChange}  />
					<label for="checkbox_2"> I agree to all <span class="txt-primary">Terms</span></label>
				</div>
				<div class="clearfix"></div>
        </FormGroup>
						
		<div class="form-group text-center">
			<LoaderButton
			  block
			  bsSize="large"
			  disabled={!this.state.validform}
			  type="submit"
			  isLoading={this.state.isLoading}
			  text="Signup"
			  loadingText="Signing up�"
			  className="btn btn-info btn-success btn-rounded"
			/>
		</div>						
        
      </form>
	  
	 </div>
	 </fragment>
    );
  }
  
  

  
  render(){
	  return (
	  <Fragment>
	  <NavBar />
	  
			<div class="pa-0 ma-0 auth-page">
				<div class="container-fluid">
					<div class="table-struct full-width full-height">	
						<div class="table-cell vertical-align-middle auth-form-wrap">
							<div class="auth-form  ml-auto mr-auto no-float">
								<div class="row">
									<div class="col-sm-12 col-xs-12">
									
									{
										this.state.newUser === null
										  ? this.renderForm()
										  : this.renderConfirmationForm()
									}
									
									</div>	
								</div>
							</div>
						</div>
					</div>	
				</div>
				
			</div>
		
	</Fragment>);
  }
  
}