import { Cache } from 'aws-amplify';

/** @class */
export default class CacheStorage {

  /**
   * Constructs a new CacheStorage object
   * @param {object} config aws-amplify cache configuration options 
   */
  constructor(config) {
    //https://aws-amplify.github.io/docs/js/cache
    var dataCacheConfig = (config)?config:{
             defaultTTL: (20*60) * 1000 //default 20 minutes set in millisconds
            ,itemMaxSize: 3000 // 3000 bytes
            ,storage: sessionStorage //default to localStorage; window storage persists per window, or TAB; therfore, logout of a tab leaves active sessions in other open tabs
            };
    var prstCacheConfig = {
             defaultTTL: (60*60) * 1000 //default 1 hour set in millisconds
            ,itemMaxSize: 3000 // 3000 bytes
            ,storage: localStorage //default to localStorage; window storage persists per window, or TAB; therfore, logout of a tab leaves active sessions in other open tabs
            };
    /*
    if(config){
        dataCacheConfig = config;
    }else{
        dataCacheConfig = 
    }
    */
    this.dataCache = Cache.createInstance(dataCacheConfig);
    this.prstCache = Cache.createInstance(prstCacheConfig);
    
    

  }

  /**
   * This is used to set a specific item in storage
   * @param {string} key - the key for the item
   * @param {object} value - the value
   * @returns {string} value that was set
   */
  setItem(key, value, prst) {
    var c = (prst)?this.prstCache : this.dataCache;
    c.setItem(key, value);
    return c.getItem(key);
  }

  /**
   * This is used to get a specific key from storage
   * @param {string} key - the key for the item
   * @returns {string} the data item
   */
  getItem(key,prst) {
	  var c = (prst)?this.prstCache : this.dataCache;
	  return c.getItem(key);
  }

  /**
   * This is used to remove an item from storage
   * @param {string} key - the key being set
   * @returns {string} value - value that was deleted
   */
  removeItem(key, prst) {
   var c = (prst)?this.prstCache : this.dataCache;
    return c.removeItem(key);
  }

  /**
   * This is used to clear the storage
   * @returns {string} nothing
   */
  clear(prst) {
    var c = (prst)?this.prstCache : this.dataCache;
    c.clear();
    return {};
  }

  /**
   * This is used to get all keys from the cache
   * @reutrns array of keys
   */
  getAllKeys(prst){
    var c = (prst)?this.prstCache : this.dataCache;
    return c.getAllKeys();
    
  }
  
  /**
   * This is used to refresh all cached data and push the expiration by the defaultTTL
   */
  refresh(includeprst){
	  
	  for(let k of this.getAllKeys()){
	    	var lrfrsh = this.getItem(k);
	        this.setItem(k,lrfrsh);
	  	  
	  }
	  if(includeprst){
	    for(let k of this.getAllKeys(true)){
	    	var prfrsh = this.getItem(k,true);
	    	this.setItem(k,prfrsh,true);
	    }
	  }
	  
  }
}