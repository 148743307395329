//import { Auth } from "aws-amplify";
import { Auth, API } from "aws-amplify";
import React, { Fragment } from "react";
import LeftNav from "../components/LeftNav"
import TopNav from "../components/TopNav"
import RightNav from "../components/RightNav"
import Footer from "../components/Footer"
import BaseComponent from "../classes/BaseComponent"

import AWS from "aws-sdk";
import https from "https";

import "./dashboard.css"


export default class dashboard extends BaseComponent {
	constructor(props) {
    super(props);

    this.state = {
      isLoading: true
      , msg: ''
      , dashboardurl: null
      , userinfo:''
      , signinurl: null
    };
  

    this.oncomponentmounted = (susr)=> {
        this.setState({"msg":"page loaded"});

        //this.showuserinfo(susr);
        this.getembeddeddashboard(susr);
    };

   
}

async showuserinfo(susr){
        try{
            this.setState({userinfo:JSON.stringify(susr.idToken)});
        }catch(e){
            this.setState("error encountered during redirect.  aborting.");
        }
    }


async getembeddeddashboard(susr){
        try{
        	
        
        //var retsigninUrl = await this.getsigninurl(susr.idToken.jwtToken,'https://us-east-1.quicksight.aws.amazon.com/sn/start');
        /*var retsigninUrl = await this.doqsauth(susr);
        if(retsigninUrl){
        	this.setState({signinurl:retsigninUrl});
        }*/
        
        //set the default dashboard ID.
        var dboardid = 'ac2da67c-d5da-4902-b81b-8a2c3bf2af5f';
        
        if(this.props.match.params.dashboardid!=undefined){
        	dboardid = this.props.match.params.dashboardid
        	
        	
        }
        
        var retval = await this.geturl(susr.idToken.jwtToken,dboardid);
            if(retval.EmbedUrl){
                this.setState({dashboardurl:retval.EmbedUrl+this.props.location.hash});
            }else{
            	alert(retval);
            }
            
            //var win = window.open(retval, '_blank');
            //win.focus();
        }catch(e){
        	alert('error encountered')
        	alert(e);
            this.setState({msg:"error encountered during redirect.  aborting."});
        }
    }
 
  
geturl(jwtToken, dashboardid){
    //dashboardid\":\"ac2da67c-d5da-4902-b81b-8a2c3bf2af5f
    return API.put('getembeddeddashboard', '/getembeddeddashboard', { body:{ "jwtToken" : jwtToken, "dashboardid":dashboardid},headers:{}});
}

getsigninurl(jwtToken, redirecturl){
    
    return API.put('getsigninurl', '/getsigninurl', { body:{ "jwtToken" : jwtToken, "redirect_url":redirecturl},headers:{}});
}


render() {
        
    return (
			<Fragment>
				<TopNav />
     			<LeftNav />
				<RightNav />
                <div className="page-wrapper">
                    <object type="text/html" data={this.state.dashboardurl} className="qdash"></object> 
					{/*  <object type="text/html" data={this.state.signinurl} className="qsightlogin"></object> */}    
				    <Footer />
			
			    </div>
            </Fragment>
		
    );
  }
  
  
}