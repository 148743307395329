import { Auth } from "aws-amplify";
import { withRouter } from "react-router-dom";
import React, { Component} from "react";
import Routes from "./Routes";

import "./App.css";


class App extends Component {

	constructor(props) {
	  super(props);

	  this.state = {
		isAuthenticated: false,
	  isAuthenticating: true
	  };
	}

	userHasAuthenticated = authenticated => {
	  this.setState({ isAuthenticated: authenticated });
	}

	handleLogout = async event => {
	  await Auth.signOut();
	  this.userHasAuthenticated(false);
	  this.props.history.push("/a/signin");
	}

	
	
	async componentDidMount() {
	  try {
		await Auth.currentSession();
		this.userHasAuthenticated(true);
	  }
	  catch(e) {
		if (e !== 'No current user') {
		  alert(e);
		}
	  }

	  this.setState({ isAuthenticating: false });
	}

  render() {
	  const childProps = {
		  isAuthenticated: this.state.isAuthenticated,
		  userHasAuthenticated: this.userHasAuthenticated
		};

        return ( <Routes childProps={childProps} /> );
	 
  }
}

export default withRouter(App);
