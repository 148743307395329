import { Auth } from "aws-amplify";
import React, { Component, Fragment } from "react";
import {
  HelpBlock,
  FormGroup,
  FormControl,
  ControlLabel,
  Label
} from "react-bootstrap";
import { Input } from 'reactstrap';
import LoaderButton from "../components/LoaderButton"
import BaseComponent from "../classes/BaseComponent"

import NavBar from "./NavBar";
import "../css/style.css";
import "./Signup.css";

export default class Signup extends BaseComponent {
  constructor(props) {
    super(props);
    this.pageaudience="public";
    
    this.state = {
      isLoading: false
	  , name: ""
      , email: ""
      , password: ""
      , confirmPassword: ""
      , confirmationCode: ""
	  , terms: ""
      , newUser: null
      , validform:false
      , errmsg: ''
      , doconfirm: false
      , resetLoadingText: "Completing reset..."

    };

      this.oncomponentmounted = async (susr)=> {
        
    };


  }

  validateForm() {
   
    return (
	  this.validateEmail(this.state.email)
    );
  }

   validateEmail(email) {
        var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(String(email).toLowerCase());
    }

  validateRecoverPasswordForm() {
    return (this.state.confirmationCode.length > 0 &&
            this.state.password.length > 0 &&
            this.state.password === this.state.confirmPassword
        )
  }

  handleChange = async event => {

    await this.setState({
      [event.target.id]: event.target.value
    });

    if(this.validateForm()){
       
        this.setState({
            validform:true
        })
    }
  }

  handleSubmit = async event => {
    event.preventDefault();
    this.setState({ isLoading: true });
    var error = '';
    var showconfirm = false;
    try {
		
        var reset = await Auth.forgotPassword(this.state.email);
        showconfirm = true;
    	
	  } catch (e) {
          switch(e.code){
              case "UsernameExistsException":
                error = 'Error encountered.  Verify your details and try again.  If you already have an account, but have forgotten the password, use the forgot password link.';
                break;
              case "InvalidParameterException":
                error = 'Please choose a stronger password.';
                break;
              case "UserNotFoundException":
                error = 'Verify your email address and try again.';
                break;
              default:
                error = JSON.stringify(e);
                break;
          }
          
	  }

    this.setState({ errmsg:error, isLoading: false, doconfirm:showconfirm });
  }

  handleConfirmationSubmit = async event => {
    event.preventDefault();

    this.setState({ isLoading: true });
	 try {
		await Auth.confirmSignUp(this.state.email, this.state.confirmationCode);
		await Auth.signIn(this.state.email, this.state.password);

		this.props.userHasAuthenticated(true);
		this.props.history.push("/");
	  } catch (e) {
		this.setState({ errmsg:e,isLoading: false });
	  }
	  
  }
  
  handlePasswordRecoverySubmit = async event => {
    event.preventDefault();
    var error='';
    if(this.validateRecoverPasswordForm()){
    this.setState({ isLoading: true });
        try {

            await Auth.forgotPasswordSubmit(this.state.email, this.state.confirmationCode, this.state.password);
            this.setState({resetLoadingText: "Initiating Sign in..."});
            await Auth.signIn(this.state.email, this.state.password);

            Auth._storage.setItem("ActiveSession",true,true);
        
            this.setState({ isLoading: false });
            this.props.history.push("/dashboard");
        } catch (e) {
            switch(e){
                case "InvalidParameterException":
                    error='Please enter a stronger password';
                break;
                default:
                    error=JSON.stringify(e);
                break;
            }
            this.setState({ errmsg: error,isLoading: false });
        }
      }
	  
  }

  renderPasswordRecoveryForm() {
    return (
        <Fragment>
        <div class="mb-30">
            <h3 class="text-center txt-dark mb-10">Forgot Password</h3>
            <h6 className={(this.state.errmsg!=='')?'text-center nonecase-font txt-greytext-warning text-warning':'text-center nonecase-font txt-greytext-warning.'}>{(this.state.errmsg!=='')?this.state.errmsg:'Check your inbox for a password reset confirmation code.'}</h6>
        </div>
        <div class="form-wrap">
            <form onSubmit={this.handlePasswordRecoverySubmit}>
                <FormGroup controlId="confirmationCode" bsSize="large">
                    <ControlLabel>Confirmation Code</ControlLabel>
                    <FormControl
                        autoFocus
                        type="tel"
                        value={this.state.confirmationCode}
                        onChange={this.handleChange}
                    />
                    <HelpBlock>Please check your email for the code.</HelpBlock>
                </FormGroup>
                <FormGroup controlId="password" bsSize="large">
                    <ControlLabel className="control-label mb-10 font-16">Password</ControlLabel>
                    <FormControl
                        value={this.state.password}
                        onChange={this.handleChange}
                        id="password"
                        type="password"
                        className="form-control txt-primary block mb-10 pull-right font-16"
                    />
                    
                </FormGroup>
                <FormGroup controlId="confirmPassword" bsSize="large">
                    <ControlLabel className="control-label mb-10 font-16">Confirm Password</ControlLabel>
                    <FormControl
                        value={this.state.confirmPassword}
                        onChange={this.handleChange}
                        type="password"
                        id="confirmPassword"
                        className="form-control txt-primary block mb-10 pull-right font-16"
                    />
                </FormGroup>
                
              
                <LoaderButton
                block
                bsSize="large"
                disabled={!this.validateRecoverPasswordForm()}
                type="submit"
                isLoading={this.state.isLoading}
                text="Reset"
                loadingText={this.state.resetLoadingText}
                />
            </form>
        </div>
        </Fragment>
    );
  }

  renderConfirmationForm() {
    return (
        <Fragment>
        <div class="mb-30">
            <h3 class="text-center txt-dark mb-10">Confirm Your Account</h3>
            <h6 className={(this.state.errmsg!=='')?'text-center nonecase-font txt-greytext-warning text-warning':'text-center nonecase-font txt-greytext-warning.'}>{(this.state.errmsg!=='')?this.state.errmsg:'Check your inbox for your confirmation code and enter below to signin.'}</h6>
        </div>
        <div class="form-wrap">
            <form onSubmit={this.handleConfirmationSubmit}>
                <FormGroup controlId="confirmationCode" bsSize="large">
                <ControlLabel>Confirmation Code</ControlLabel>
                <FormControl
                    autoFocus
                    type="tel"
                    value={this.state.confirmationCode}
                    onChange={this.handleChange}
                />
                <HelpBlock>Please check your email for the code.</HelpBlock>
                </FormGroup>
                <LoaderButton
                block
                bsSize="large"
                disabled={!this.validateConfirmationForm()}
                type="submit"
                isLoading={this.state.isLoading}
                text="Verify"
                loadingText="Verifying..."
                />
            </form>
        </div>
        </Fragment>
    );
  }

  renderForm() {
    return (
	
	<fragment>
	<div class="mb-30">
		<h3 class="text-center txt-dark mb-10">Forgot Password</h3>
		<h6 className={(this.state.errmsg!=='')?'text-center nonecase-font txt-greytext-warning text-warning':'text-center nonecase-font txt-greytext-warning.'}>{(this.state.errmsg!=='')?this.state.errmsg:'Enter your email address below and select the Reset Password button..'}</h6>
	</div>
	<div class="form-wrap">
      <form onSubmit={this.handleSubmit}>
	     <FormGroup controlId="name" bsSize="large"  class="form-group">
            <FormGroup controlId="email" bsSize="large">
            <ControlLabel className="control-label mb-10 font-16">Email</ControlLabel>
            <FormControl
                type="email"
                value={this.state.email}
                id="email"
                onBlur={this.handleChange}
                onChange={this.handleChange}
                className="form-control txt-primary block mb-10 pull-right font-16"
            />
            </FormGroup>
        </FormGroup>
       				
		<div class="form-group text-center">
			<LoaderButton
			  block
			  bsSize="large"
			  disabled={!this.state.validform}
			  type="submit"
			  isLoading={this.state.isLoading}
			  text="Reset Password"
			  loadingText="Initiating reset..."
			  className="btn btn-info btn-success btn-rounded"
			/>
		</div>						
        
      </form>
	  
	 </div>
	 </fragment>
    );
  }
  
  

  
  render(){
	  return (
	  <Fragment>
	  <NavBar />
	  
			<div class="pa-0 ma-0 auth-page">
				<div class="container-fluid">
					<div class="table-struct full-width full-height">	
						<div class="table-cell vertical-align-middle auth-form-wrap">
							<div class="auth-form  ml-auto mr-auto no-float">
								<div class="row">
									<div class="col-sm-12 col-xs-12">
									
									{
										(this.state.newUser === null)
                                          ? !(this.state.doconfirm)
                                                ?this.renderForm()
                                                :this.renderPasswordRecoveryForm()
										  : this.renderConfirmationForm()
									}
									
									</div>	
								</div>
							</div>
						</div>
					</div>	
				</div>
				
			</div>
		
	</Fragment>);
  }
  
}