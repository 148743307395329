import { Auth } from "aws-amplify";
import React, { Fragment, Component } from "react";

import Select from 'react-select';
import AWS from 'aws-sdk';
import CONFIG from "../config.js";
import { FormGroup, FormControl, ListGroup, ListGroupItem, Modal, Radio} from "react-bootstrap";
import { LinkContainer } from "react-router-bootstrap";
//import Routes from "../Routes";
import LeftNav from "../components/LeftNav"
import TopNav from "../components/TopNav"
import RightNav from "../components/RightNav"
import Footer from "../components/Footer"
import ReactTable from "react-table";
import BaseComponent from "../classes/BaseComponent"
import LoaderButton from "../components/LoaderButton"

import Domain_DKIM from "../components/domain_dkim.js"


import SweetAlert from 'sweetalert-react';

import { Nav, Navbar, NavItem } from "react-bootstrap";

import '../css/react-table.css';
import '../dist/css/style.css';
import '../vendors/bower_components/sweetalert/dist/sweetalert.css'
import './domains.css';

import spfxdata from "../classes/spfxdata"
import spfxdns from "../classes/spfxdns"

AWS.config.update({  region: CONFIG.cognito.REGION } );





async function loaddomaindata(){
	
}


export default class domains extends BaseComponent {
	constructor(props) {
    super(props);

    this.logout=()=>{
    	alert('dologout');
    	this.props.history.push('/signin');
    }
    
    
    this.spfxd = new spfxdata();
    this.vspfx = new spfxdns();

    this.state = {
         msg:''
      , includedomains:[]
      , domaindata: []
      , selecteddomain: {}
      , selecteddomainsenders: []
      , selectedrow:1
      , selectedsenderrow:1
      , selectedsenderdomain:''
      , txtincludedomain:''
      , txtsendingdomain:''
      , rdoaddpolicy:''
      , rdoaddaudit:''
      , rdosourceauditing:''
      , rdoemailcapture:'0'
      , rdoipindexing:''
      , showAddSenderModal:false
      , isvalidatingsenderecord:false
      , modalmsg:''
      , sendingdomaindata:[]
      , rdodefaultdmarcaction:''
      , rdodefaultspfauthpolicy:''
      , activesendertab:'home'
      , showalert:false
      , dnsdata:[]
      , isinsert:''
      , audittrails:[]
      , showAddHostedDomainModal:false
      , showDkimDomainModal:false
      , domainkeys:[]
      , selecteddomainkeys:[]
      , txtkeyname:''
      , txtkeytype:''
      , txtkeyvalue:''
      , txthosteddomain:''
      , isvalidatinghostedomain:false
      , rdoadddomainpolicy:'-'
      , rdoadddomainaudit:0
      , rdoadddirectipindexing:0
      , rdoaddemailcapture:0
      , rdoenablerelay:0
      , rdoaddenablerelay:0
      , showconfirmeddeletealert:false
      , userrole: 'READONLY'
      , readonlyview:true
      , txtdmarcPct:0
      , txtrualist:''
      , txtruflist:''
      , usrcreds:{}
      , wsclient:{}
      , livefeedmessages:{}
      , wsisconnecting:false
      , selectedsenderdkimns:{}
    
      };
     
      
     this.openModal = this.openModal.bind(this);
     this.closeModal = this.closeModal.bind(this);
     
     this.deleteSender = this.deleteSender.bind(this);
     this.confirmDeleteSender = this.confirmDeleteSender.bind(this);
     this.sendertabclick = this.sendertabclick.bind(this);
     this.resetAllSettings = this.resetAllSettings.bind(this);
     this.copyDnsToClipboard = this.copyDnsToClipboard.bind(this);
     this.copyAuditTrailsToClipboard = this.copyAuditTrailsToClipboard.bind(this);
     

     
     
     this.oncomponentmounted = async (susr)=> {
        
        
        this.spfxd.setuser(susr);
        
        //this.setState({sendingdomaindata:(sdata), domaindata:ddata});
              
        var tstate = {};
        
         try{
	   	 tstate.domaindata = await this.spfxd.getdomainsforuser();
	   	 
	   	 //tstate.userrole = (await this.spfxd.getuserprivileges()).role;
	   	
	   	 
	       if(tstate.domaindata){
	           if(tstate.domaindata.length>0){
	               tstate.selecteddomain = tstate.domaindata[0];
	               tstate.userrole = tstate.domaindata[0].userrole;
	               tstate.readonlyview =(tstate.userrole=='READONLY');
	               tstate.rdoenablerelay = tstate.domaindata[0].enableRelayServices;
	               
	               var sdata = await this.spfxd.getauthorizedsenders(tstate.selecteddomain.spfxio_id);//hosteddomainid);
	               tstate.selecteddomainsenders = sdata;
	               tstate.selectedsenderdomain = (sdata)?(sdata.length>0)?sdata[0]:'':'';
	               tstate.rdodefaultspfauthpolicy = tstate.selecteddomain.defaultSpfPolicy;
	               tstate.rdosourceauditing = tstate.selecteddomain.defaultSourceAuditing;
	               tstate.rdoipindexing = tstate.selecteddomain.enableIpIndexing;
	               tstate.rdoemailcapture = tstate.selecteddomain.enableEmailCapture;
	               tstate.rdodefaultdmarcaction = tstate.selecteddomain.defaultDmarcPolicy;
	               tstate.txtrualist = tstate.selecteddomain.dmarcRua;
		           tstate.txtruflist = tstate.selecteddomain.dmarcRuf;
		           tstate.txtdmarcPct = (tstate.selecteddomain.dmarcPct)*100;
	
	              
	               var nameservers = {};
			        try{
			          nameservers= await this.spfxd.getdomainkeynameservers(tstate.selecteddomain.spfxio_id);
			        
			         }catch(nserr){
			          nameservers=nserr;
			         }
			       tstate.selectedsenderdkimns = nameservers;
			       
	           }
	       }else{
	       	tstate.msg = 'We couldn\'t find any domains for you.  Please be sure you are signed in.';
	       }
         }catch(err){
        	 tstate.msg = 'We encountered an error while searching for your domains.  Please be sure you are signed in.';
         }
         
	     this.setState(tstate);
      
    }
     
     
     
}
	
//-----------------------------------------------------------------------------------------------------------------------------  
async getUserInfo(){
	
	return new Promise((resolve,reject)=>{
	
		Auth.currentUserInfo().then(info=>{
			resolve(info);
		}).catch(err=>{
			reject(err);
		});
		
	});
	
}
//-----------------------------------------------------------------------------------------------------------------------------
 async getCredentials(){

	return new Promise(function(resolve,reject){
	
		Auth.currentCredentials()
		  .then(credentials => {
			  resolve(credentials);
		  }).catch(err=>{
			 reject(null);
		});
	}).then(function(data){
		return {data: data.data.Credentials};
	}).catch(function (err){
		return {error:err};
	});
 }
//-----------------------------------------------------------------------------------------------------------------------------		 
 /*
  * handleDeleteRow - removes added spf domain from Add Sender modal
 */
handleDeleteRow = (domain,event) =>{
    event.preventDefault();
    
    var includes = this.state.includedomains;
    includes.splice(includes.indexOf(domain),1);
    this.setActiveState({includedomains:includes});
}
//-----------------------------------------------------------------------------------------------------------------------------
 handleAddHostedDomain = async (event) =>{
	 event.preventDefault();
	 
	 this.setState({isvalidatinghosteddomain:true});
	 
	 var params = {};
	 params.spfx = { 
		  domain:this.state.txthosteddomain
		, defaultSpfPolicy:this.state.rdoadddomainpolicy
		, defaultSourceAuditing:this.state.rdoadddomainaudit
		, enableIpIndexing:this.state.rdoadddirectipindexing
		, enableRelayServices:this.state.rdoaddenablerelay
		, enableEmailCapture: this.state.rdoaddemailcapture
		, orgid: 1
		, dmarcpolicy:CONFIG.dmarcpolicy
		, dmarcrua:CONFIG.dmarcrua
		, dmarcruf:CONFIG.dmarcruf
		, dmarcpct:CONFIG.dmarcpct
	 }
	 
	
	 
	 var tstate={};
	 var res = await this.spfxd.addhosteddomain(params);
	 if(res.err){
		 tstate.msg = res.err
	     tstate.showAddHostedDomainModal = false;
	 }else{
	     var d = {};
	     d.domain = params.spfx.domain;
	     d.spfxio_id = res.spfxio_id;
	     d.hostedZoneId = res.hostedZoneId;
	     d.hosteddomainid=res.hosteddomainid;
	     d.defaultSpfPolicy=params.spfx.defaultSpfPolicy
	     d.defaultSourceAuditing=params.spfx.defaultSourceAuditing
	     d.enableRelayServices=params.spfx.enableRelayServices
	     d.defaultDmarcPolicy='none'
	     d.enableEmailCapture=params.spfx.enableEmailCapture
	     d.enableIpIndexing=params.spfx.enableIpIndexing;
	     
	     var domaindata = this.state.domaindata;
	     domaindata.push(d);
	     
	     tstate.domaindata = domaindata;
	     
	     if(tstate.domaindata){
	         if(tstate.domaindata.length>0){
	             tstate.selecteddomain = tstate.domaindata[tstate.domaindata.length-1];
	             tstate.selecteddomainsenders = [];
	             tstate.selectedrow = tstate.domaindata.length;
	             tstate.rdodefaultspfauthpolicy = tstate.selecteddomain.defaultSpfPolicy;
	             tstate.rdosourceauditing = tstate.selecteddomain.defaultSourceAuditing;
	             tstate.rdoemailcapture = tstate.selecteddomain.enableEmailCapture;
	             tstate.rdoipindexing = tstate.selecteddomain.enableIpIndexing
	             tstate.rdodefaultdmarcaction = tstate.selecteddomain.defaultDmarcPolicy;
	             tstate.rdoenablerelay = tstate.selecteddomain.enableRelayServices;
	             tstate.txtrualist = tstate.selecteddomain.dmarcRua;
	             tstate.txtruflist = tstate.selecteddomain.dmarcRuf;
	             tstate.txtdmarcpct = tstate.selecteddomain.dmarcPct;
	         }
	     }
	             
	     
	     tstate.msg = "Domain Added"
	     tstate.showAddHostedDomainModal = false;
    
	 }
     tstate.isvalidatinghosteddomain = false;
     this.setActiveState( tstate );
 }
//----------------------------------------------------------------------------------------------------------------------------- 
 /*
  * handleAddAuthorizedSender -  verifies data gathered from the Add Sender modal and saves with help of spfxdata
 */
 handleAddAuthorizedSender = async (event) =>{
    event.preventDefault();

    this.setActiveState({isvalidatingsenderecord:true});
    //alert(this.state.txtsendingdomain);
    var vres = await this.vspfx.verifyDomain(this.state.txtsendingdomain);
   
    //if form details are valid, and txtsendingdomain is verified, then add the domain
    if(this.validateAddSenderForm() && vres.isvalidformat ){
        //var sendingdomains = this.state.sendingdomaindata;
        var sendingdomains = this.state.selecteddomainsenders;
      
        
        var exists = false;
        for(let sd of this.state.selecteddomainsenders){
            exists = (sd.senderdomain == this.state.txtsendingdomain) || exists;
        }
        
        
        if(!exists || this.state.isinsert==false){

            var d = {}
            d.hosteddomainid = this.state.hosteddomainid
            d.senderdomain = this.state.txtsendingdomain
            d.includes = this.state.includedomains
            d.defaultSpfPolicy = this.state.rdoaddpolicy;
            d.defaultSourceAuditing = this.state.rdoaddaudit;
            d.enableEmailCapture = this.state.rdoaddemailcapture;
            //d.key = (this.state.isinsert==true)?sendingdomains.length+1:this.state.selectedsenderrow
            d.key = sendingdomains.length+1
            //d.key = Math.random().toString(36).substring(7);
            
            var pparams = {};
            
            pparams.spfx = {
                     spfxio_id: this.state.selecteddomain.spfxio_id  //spfxio hosted domain id
                   , domain: this.state.selecteddomain.domain
                   , senderdomain: d.senderdomain
                   , includehosts:  d.includes //must conform to dns formatted names
                   , defaultSpfPolicy:  d.defaultSpfPolicy//[~||-||?]
                   , defaultSourceAuditing: d.defaultSourceAuditing //([true || false] as bit
                   , enableEmailCapture : d.enableEmailCapture
            };
            
            
            var res = await this.spfxd.addauthorizedsender(pparams);
            if(res.err){
	           	 this.setActiveState({isvalidatingsenderecord:false
	           		 , modalmsg:res.err
	             });
            }else{
	           	if(this.state.isinsert==true){
	           		sendingdomains.push(d);
		           	 this.setState({isvalidatingsenderecord:false
		                 , includedomains:[]
		           	 	 , domainkeys:[]
		                 , sendingdomaindata:sendingdomains
		                 , txtsendingdomain:''
		                 , modalmsg:'Authorized sender added.  You may add another, or close the window.'
		             });
		           	 
	           	}else{
	           		
	           		
	           		sendingdomains.splice(this.state.selectedsenderrow-1,1,d);
	           		
		           	 this.setActiveState({isvalidatingsenderecord:false
			           		 , selecteddomainsenders:sendingdomains
			           		 , selectedsenderdomain:d
			           		 , modalmsg: "Authorized sender updated."
			             });
		           	
		           	 
		           	
	           	}
           }
           

        }else{
            this.setActiveState({isvalidatingsenderecord:false
                            , modalmsg:'Sending Domain already exists.  Sending Domain must be unique.'
                        });
        }
    }else{

        //set message in the modal
        this.setActiveState({modalmsg:'Please complete all required fields and try again.',isvalidatingsenderecord:false});
    }     

    this.setActiveState({isvalidatingsenderecord:false});

}
//-----------------------------------------------------------------------------------------------------------------------------

handleAddDKIM = async (event) =>{
    event.preventDefault();

	var keyinfo = {};
		keyinfo.keyname = this.state.txtkeyname;
		keyinfo.type = this.state.txtkeytype;
		keyinfo.value= this.state.txtkeyvalue;
	var seldkeys = this.state.selecteddomainkeys;
		seldkeys.push(keyinfo);
		
	this.setActiveState({
		selecteddomainkeys: seldkeys
	});
	
	

}

//-----------------------------------------------------------------------------------------------------------------------------

async validateAddSenderForm() {
     
        
    var res = (this.state.includedomains.length >= 0
            && this.state.txtsendingdomain.length > 0
            && this.state.rdoaddpolicy.length>0
        );
       
        return res;
}
//-----------------------------------------------------------------------------------------------------------------------------
  handleTxtFieldChange = event => {
    this.setState({
      [event.target.id]: event.target.value
    });
  }
//-----------------------------------------------------------------------------------------------------------------------------  
handleSelectChange = (event,id) =>{

	var st = {};
	st[id]=event.value;
	this.setActiveState(st);
	
	
}  
//-----------------------------------------------------------------------------------------------------------------------------
handleRdoChange = event => {
      
        this.setState({
            [event.target.name]: event.target.value
            });
                
    
}
//-----------------------------------------------------------------------------------------------------------------------------
handleChange = event => {
	    this.setState({
	      [event.target.name]: event.target.value
	    });
}
//-----------------------------------------------------------------------------------------------------------------------------
updateDmarcPolicy = async(event) =>{
	 
	try{
	
	this.setActiveState( {      msg: '' });
	
	 var pparams = {};
	 pparams.spfx = {
			   spfxio_id: this.state.selecteddomain.spfxio_id
			 , domain: this.state.selecteddomain.domain
			 , dmarcpolicy: this.state.rdodefaultdmarcaction
			 , dmarcrua: this.state.txtrualist
			 , dmarcruf: this.state.txtruflist
			 , dmarcpct: this.state.txtdmarcPct
			 
	 }
	 
	 await this.spfxd.addhosteddomaindmarcpolicy(pparams).then((res)=>{
    	 
    	 var d = this.state.selecteddomain;
     	 d.defaultDmarcPolicy = pparams.spfx.dmarcpolicy;
     	 d.dmarcRua = pparams.spfx.dmarcrua;
     	 d.dmarcRuf = pparams.spfx.dmarcruf;
     	 d.dmarcPct = pparams.spfx.dmarcpct;
     
         
         this.setActiveState( {      
         	 					  msg: 'Record updated successfully.'
          						, selecteddomain: d
		  						, showalert: false 
		  					});
     
     }).catch((err)=>{
    	 this.setActiveState( {      msg: 'error updating record.' + err });
     });
     
     
	  
	 }catch(err){
	  this.setActiveState( { msg: 'error updating record.  please try again:' + JSON.stringify(err,0,10) });
	 }
	 
}
//----------------------------------------------------------------------------------------------------------------------------- 
updateSenderSettings = async(event) => {
	  
	 try{
		 var pparams = {};
	
      
      pparams.spfx = {
               spfxio_id: this.state.selecteddomain.spfxio_id
             , domain: this.state.selecteddomain.domain
             , defaultSpfPolicy:  this.state.rdodefaultspfauthpolicy
             , defaultSourceAuditing: this.state.rdosourceauditing
             , enableIpIndexing: this.state.rdoipindexing
             , enableEmailCapture: this.state.rdoemailcapture
             , enableRelayServices: this.state.rdoenablerelay
      };

      
      var res = await this.spfxd.addhosteddomainpolicy(pparams);
      
 	  if(res.err){
 		this.setActiveState( {      msg: 'error updating record.' });
 	  }
 	  else{
      var d = this.state.selecteddomain;
       	  d.defaultSpfPolicy = this.state.rdodefaultspfauthpolicy;
          d.defaultSourceAuditing = this.state.rdosourceauditing;
          d.enableIpIndexing = this.state.rdoipindexing;
          d.enableEmailCapture = this.state.rdoemailcapture;
          
          this.setActiveState( {      msg: 'Record updated successfully.'
 		  						, selecteddomain: d
 		  						, showalert: false 
 		  					});
 	  
 	  }
 	  
	 }catch(err){
	  this.setActiveState( { msg: 'error updating record.  please try again.' });
	 }
	 
}
//-----------------------------------------------------------------------------------------------------------------------------
cascadeSenderSettings = async(event) => {

	 var updatedsenders = [];
	 var errorencountered=false;
	 for(let sdr of this.state.selecteddomainsenders){
		 if((sdr.defaultSpfPolicy != this.state.rdodefaultspfauthpolicy || sdr.defaultSourceAuditing != this.state.rdosourceauditing || sdr.enableEmailCapture != this.state.rdoemailcapture) && errorencountered==false){
			   //alert(`cascade policy for ${JSON.stringify(sdr)}`);
			   sdr.defaultSpfPolicy = this.state.rdodefaultspfauthpolicy;
	           sdr.defaultSourceAuditing = this.state.rdosourceauditing;
	           sdr.enableEmailCapture = this.state.rdoemailcapture;
	            
	            var pparams = {};
	            
	            pparams.spfx = {
	                     spfxio_id: this.state.selecteddomain.spfxio_id  //spfxio hosted domain id
	                   , domain: this.state.selecteddomain.domain
	                   , senderdomain: sdr.senderdomain
	                   , includehosts:  sdr.includes //must conform to dns formatted names
	                   , defaultSpfPolicy:  sdr.defaultSpfPolicy//[~||-||?]
	                   , defaultSourceAuditing: sdr.defaultSourceAuditing //([true || false] as bit
	                   , enableEmailCapture: sdr.enableEmailCapture
	            };
	            
	            //alert(`add auth sender with params ${pparams}`);
	            var res = await this.spfxd.addauthorizedsender(pparams);
	            if(res.err){
	            	errorencountered=true;
	            }else{
	            	updatedsenders.push(sdr.senderdomain);
	            }
		 }
	 }
	 var msg = 'Policies synchronized.  No updates necessary.'
	 if(errorencountered==true){
		 
		 msg = "error encountered cascading policy."
		 if(updatedsenders.length>0){
			 msg += 'Policies updated for senders: ' + updatedsenders.join(',');
		 }
		 
	 }else if(updatedsenders.length>0){
		 msg = 'Policies updated for senders: ' + updatedsenders.join(',');
	 }
	 
	 this.setActiveState({msg:msg});
  }
//-----------------------------------------------------------------------------------------------------------------------------
openModal(modaltype){
	 
	 	switch(modaltype){
	 	case 'update':
	 		
	 		var d = this.state.selectedsenderdomain;
	 		
	 		this.setActiveState({     showAddSenderModal:true
	 						  , txtsendingdomain:d.senderdomain
	 						  , includedomains:d.includes
	 						  , rdoaddpolicy:d.defaultSpfPolicy
	 						  , rdoaddaudit:d.defaultSourceAuditing
	 						  , rdoaddemailcapture:d.enableEmailCapture
	 						  , isinsert:false
	 						  , hosteddomainid:d.hosteddomainid
	 					  });
	 	break;
	 	case 'addhostedomain':
	 		this.setActiveState({
	 					  showAddHostedDomainModal:true
	 					, modalmsg:''
	 				});
	 		
	 			
	 		break;
	 	case 'managedkim':
	 	
	 		var selkeys = this.state.selectedsenderdomain.dkimkeys;
	 		
	 		this.setState({
	 			  showDkimDomainModal:true
	 			, selecteddomainkeys:selkeys
				, modalmsg:''
			});
			
			
			
			
			
	 		break;
	 	default:
	 		this.setActiveState({   rdoaddpolicy: this.state.selecteddomain.defaultSpfPolicy
        				, rdoaddaudit:this.state.selecteddomain.defaultSourceAuditing
        				, rdoaddemailcapture:this.state.selecteddomain.enableEmailCapture
        				, rdoaddenablerelay:this.state.selecteddomain.enableRelayServices
        				, showAddSenderModal:true
        				, isinsert:true});
	 	break;
	    
	 	}
}
//-----------------------------------------------------------------------------------------------------------------------------
closeModal(){
       this.setActiveState({showAddSenderModal:false, modalmsg:'',showAddHostedDomainModal:false,showDkimDomainModal:false});
}
//-----------------------------------------------------------------------------------------------------------------------------
closeactivewssubscriptions = async()=>{
	if(this.state.wsclient.readyState){
		if (this.state.wsclient.readyState === this.state.wsclient.OPEN){
			this.state.wsclient.close();
			
			var arrmsg = []
	    	var clvmsg = this.state.livefeedmessages;
	    	if(this.state.livefeedmessages[this.state.selecteddomain.spfxio_id]){
	    		arrmsg = clvmsg[this.state.selecteddomain.spfxio_id];	
	    	}
	    	arrmsg.unshift({timestamp: new Date().getTime(), message: { recclass:'DCONN', body:'disconnnecting Live Feed for ' + this.state.selecteddomain.spfxio_id}});
	    	clvmsg[this.state.selecteddomain.spfxio_id]=arrmsg;
			
			this.setState({  livefeedmessages:clvmsg });
			
		}
	}
}
//-----------------------------------------------------------------------------------------------------------------------------
wsconnectionactive = ()=>{
	var retval = false;
	if(this.state.wsclient.readyState){
		if (this.state.wsclient.readyState === this.state.wsclient.OPEN){
			retval = true;
		}
	}
	return retval;
}
//-----------------------------------------------------------------------------------------------------------------------------
exportwsmessages = () =>{
	var arrmsg = []
	var clvmsg = this.state.livefeedmessages;
	if(this.state.livefeedmessages[this.state.selecteddomain.spfxio_id]){
		arrmsg = clvmsg[this.state.selecteddomain.spfxio_id];	
	
	
	
	var hdr = {};
		hdr.message = {};
		hdr.timestamp = "Date";
		hdr.message.recclass = "Class"
		hdr.message.smtphostdomain = "SmtpHostDomain";
		hdr.message.smtphostip = "SmtpIp";
		hdr.message.senderdomain = "EmailDomain";
		hdr.message.senderaddress = "EmailAddress";
		
		  
	   var dtout = [hdr];
	   dtout = dtout.concat(this.state.livefeedmessages[this.state.selecteddomain.spfxio_id])
	   
	   
	   var textField = document.createElement('textarea')
	   textField.innerHTML = this.buildplaintextlivefeed(dtout);
	   document.body.appendChild(textField)
	   textField.select()
	   document.execCommand('copy')
	   textField.remove()
	   
	   //e.target.focus();
	   this.setState({ msg: 'Live feed records copied to clipboard.' });
	   
	   
	}
	   
	   
	
}
//-----------------------------------------------------------------------------------------------------------------------------
clearmessages = () =>{
	var arrmsg = []
	var clvmsg = this.state.livefeedmessages;
	if(this.state.livefeedmessages[this.state.selecteddomain.spfxio_id]){
		clvmsg[this.state.selecteddomain.spfxio_id] = []	
	}
	this.setState({  livefeedmessages:clvmsg });

}
//-----------------------------------------------------------------------------------------------------------------------------
connectlivefeed = async() =>{
	
	try{
		
		this.setActiveState({wsisconnecting:true});
		const ws = await this.spfxd.connectws();
		let that = this;
		//this.setState({msg:'Establishing Live Feed connection.  Please wait.'});
		ws.onerror = function() {
		    //alert('Connection Error');
			//this.setActiveState({msg:'Error establishing connection to the live feed.'});
		};

		ws.onopen = function() {
		    //alert('WebSocket Client Connected');
			
		    function sendmsg(msg) {
		        if (ws.readyState === ws.OPEN) {
		            ws.send(JSON.stringify(msg));
		        }
		    }
		    
		    that.setState({ msg: 'Live Feed connection established.'});
		   
			
		    
		  sendmsg({'action':'subscribe', 'spfxioid':that.state.selecteddomain.spfxio_id});
		  
		};

		ws.onclose = function() {
			that.setActiveState({msg:'Live Feed connection closed.'});
		};

		ws.onmessage = ((e)=>{
		    if (typeof e.data === 'string') {
		        
		    	var obj = JSON.parse(e.data);
		    	var arrmsg = []
		    	var clvmsg = that.state.livefeedmessages;
		    	if(that.state.livefeedmessages[that.state.selecteddomain.spfxio_id]){
		    		arrmsg = clvmsg[that.state.selecteddomain.spfxio_id];	
		    	}
		    	if(obj.timestamp==undefined)
		    		obj.timestamp = new Date().getTime();
		    	if(obj.message == undefined){
		    		obj.message = {};
		    		obj.message.recclass="CONN";
		    		obj.message.body = obj.msg;
		    		delete obj.msg;
		    	}
		    		
		    	
		    	arrmsg.unshift(obj);
		    	if(arrmsg.length>100){
		    		arrmsg.pop();
		    	}
		    	
		    	clvmsg[that.state.selecteddomain.spfxio_id]=arrmsg;
				
				that.setState({  livefeedmessages:clvmsg });
				
		    }
		    	
		    
		});
		
		
		this.setState ( {wsclient:ws});
		
		
		
		
	}catch(err){
		this.setState({ msg: 'Error establishing Live Feed connection.' + JSON.stringify(err)})
	}
	
	this.setActiveState({wsisconnecting:false});
	
}
//-----------------------------------------------------------------------------------------------------------------------------
sendertabclick = async (tab, event)=>{
	
	if(event)
		event.preventDefault();
	
	this.closeactivewssubscriptions();
	
	switch(tab){
	case 'dns':
		await this.spfxd.getdnsrecordsfordomain(this.state.selecteddomain.spfxio_id).then((data)=>{
			this.setActiveState({dnsdata:data});
		}).catch((err)=>{
			this.setActiveState({msg:'Error loading DNS for domain.  Please try again, or contact support.'});
		});
		
		break;
	case 'audit':
		await this.setaudittrails(this.state.selecteddomain.spfxio_id);
		
		break;
	case 'livefeed':
		await this.connectlivefeed();
		
		
		break;
	}
	    
    this.setActiveState({activesendertab:tab})

 }
 
 		
 async receivesqs(sqs,params){
	 
 	 return new Promise(function(resolve,reject){
	
		 sqs.receiveMessage(params, function(err, data) {
			  if (err) {
				  reject(err);
			  }
			  else {    
				 resolve(data);
			  }
				  
				  
		});
			  
	 }).then(function(data){
		 return data;
	 }).catch(function(err){
		 return ({error:err});
	 });
	 
 }
 
 async setaudittrails(spfxio_id){
	 var data = await this.spfxd.getaudittrails(spfxio_id);
	 this.setState({audittrails:data});
 }
 
 renderdnsrecords(dnsrecords){
	 return [{}].concat(dnsrecords).map(
			    (dnsrecord, i) =>
			      i !== 0 && dnsrecord
			        ? 
			            <ListGroupItem>
			             <div class="form-group">
			             	<span> {dnsrecord.Name.replace('\\052','*')} </span>
			                <span> {dnsrecord.Type} </span>
			                <span> {dnsrecord.TTL} </span>
			             	<span> {dnsrecord.Value}</span>
			            </div>
			            </ListGroupItem>
			          
			        :   <Fragment />
			          
			  );
 }
 
 
 renderlivefeed(messages){
	 return [{}].concat(messages).map(
			    (message, i) =>
			      i !== 0
			        ? 
			            <ListGroupItem>
			             	<span> { JSON.stringify(message) } </span>
			            </ListGroupItem>
			        :   <Fragment />
			          
			  );
 }
 
 buildplaintextdnsrecords(dnsrecords){
	 var strret = ''
	 for(let dnsrecord of dnsrecords){
		 strret += dnsrecord.Name.replace('\\052','*') + '\t'
		 strret += dnsrecord.Type + '\t';
		 strret += dnsrecord.TTL + '\t';
		 strret += dnsrecord.Value + '\r\n';
	 }
	 return strret;
}
 
 buildplaintextaudittrails(trails){
	 var strret = ''
	 for(let trail of trails){
		 strret += trail.actiondate + '\t';
		 strret += trail.src + '\t';
		 strret += trail.action + '\t';
		 strret += trail.emailaddress + '\t';
		 strret += trail.configs + '\r\n'
	 }
	 return strret;
}

formatevtplaintextfield(fld){
	
	if(fld){
		return fld.toString().replace(/\r?\n|\r/g,'');
	}else{
		return '-';
	}
	
	
}

buildplaintextlivefeed(events){
	 var strret = ''
     var row = 0;
	 for(let evt of events){
		 strret += ((row>0)?this.formatevtplaintextfield(new Date(evt.timestamp).toString()):evt.timestamp) + '\t';
		 strret += this.formatevtplaintextfield(evt.message.recclass) + '\t';
		 strret += this.formatevtplaintextfield(evt.message.smtphostdomain) + '\t';
		 strret += this.formatevtplaintextfield(evt.message.smtphostip) + '\t';
		 strret += this.formatevtplaintextfield(evt.message.senderdomain) + '\t'
		 strret += this.formatevtplaintextfield(evt.message.senderaddress) + '\t'
		 strret += this.formatevtplaintextfield(evt.message.senderdomain) + '\t'
		 strret += ((row>0)?this.formatevtplaintextfield(JSON.stringify(evt.message)):'MessageSource');
		 strret += '\r\n';
		 row++;
	 }
	 return strret;
}
 
 renderspfupdatemenuitems(readonlymenu){
	 
	 
	 if(!readonlymenu && this.state.userrole=='WRITE'){
		 return (
             	<Fragment>
	             	<li><a href="#" onClick={this.updateSenderSettings} data-toggle="modal" data-target="#responsive-modal">Update Policy</a></li>
	                <li><a href="#" onClick={this.cascadeSenderSettings} data-toggle="modal" data-target="#responsive-modal">Cascade Policy</a></li>
	                <li><a href="#" onClick={this.openModal} data-toggle="modal" data-target="#responsive-modal">Add Sender</a></li>
	                <li><a href="#" onClick={(e) => this.openModal('update', e)} data-toggle="modal" data-target="#responsive-modal">Manage SPF</a></li>
	                <li><a href="#" onClick={(e) => this.openModal('managedkim', e)} data-toggle="modal" data-target="#responsive-modal">Manage DKIM</a></li>
	                <li><a href="#" onClick={this.confirmDeleteSender} data-toggle="modal" data-target="#responsive-modal">Delete Sender</a></li>
	                <li><a href="#" onClick={(e) => this.resetAllSettings('spf', e)} data-toggle="modal" data-target="#responsive-modal">Reset SPF</a></li>
                </Fragment>
            );
	 }else if(readonlymenu){
		 return (
			 	<Fragment />	             		
	            );
	 }
	 
 }
 
 renderselecteddomainmenuitems(readonlymenu){
	 
	 if(!readonlymenu && this.state.userrole=='WRITE'){
		 return (
             	<Fragment>
	             	 <li><a href="#" onClick={(e) => this.resetAllSettings('all', e)} data-toggle="modal" data-target="#responsive-modal">Reset All</a></li>
                </Fragment>
            );
	 }else if(readonlymenu){
		 return (
			 	<Fragment>
			 		{/*<li><a href="#" onClick={this.openModal} data-toggle="modal" data-target="#responsive-modal">Run SPF Test</a></li>
			 		<li><a href="#" onClick={this.openModal} data-toggle="modal" data-target="#responsive-modal">Run DMARC Test</a></li>*/}
			 	</Fragment>
	            );
	 }
	 
 }
 
 renderdomainmenuitems(readonlymenu){
	 if(!readonlymenu && this.state.userrole=='WRITE'){
		 return (
				 <Fragment>
				 	<li><a href="#" onClick={(e) => this.openModal('addhostedomain', e)} data-toggle="modal" data-target="#responsive-modal">Add Domain</a></li>
					<li><a href="#">Delete Selected Domain</a></li>
					<li><a href="#">Disable Domain</a></li>
				 </Fragment>
		 )
	 }else if(readonlymenu){
		 return (
				 	<Fragment>
				 		
				 	</Fragment>
		            );
		 }
 }
 
 renderaudittrailmenuitems(readonlymenu){
	 if(!readonlymenu && this.state.userrole=='WRITE'){
		 return(<Fragment/>)
	 }else if(readonlymenu){
		 return (
				 <Fragment>
				 	<li><a href="#" onClick={(e) => this.copyAuditTrailsToClipboard('',e)} data-toggle="modal" data-target="#responsive-modal">Copy Audit Trails</a></li>
				 </Fragment>
		 )
	 }
 }
 renderdnsmenuitems(readonlymenu){
	 if(!readonlymenu && this.state.userrole=='WRITE'){
		 return(<Fragment/>)
	 }else if(readonlymenu){
		 return (
				 <Fragment>
				 	<li><a href="#" onClick={(e) => this.copyDnsToClipboard('',e)} data-toggle="modal" data-target="#responsive-modal">Copy Records</a></li>
				 </Fragment>
		 )
	 }
 }
 
 renderdmarcmenuitems(readonlymenu){
	 if(!readonlymenu && this.state.userrole=='WRITE'){
		 return(
			<Fragment>
			 <li><a href="#" onClick={this.updateDmarcPolicy} data-toggle="modal" data-target="#responsive-modal">Update Policy</a></li>
	         <li><a href="#" onClick={(e) => this.resetAllSettings('dmarc', e)} data-toggle="modal" data-target="#responsive-modal">Reset DMARC</a></li>
		 	</Fragment>
		 )
	 }else if(readonlymenu){
		 return (
				 <Fragment />
		 )
	 }
 }
 renderdkimmenuitems(readonlymenu){
	 if(!readonlymenu && this.state.userrole=='WRITE'){
		 return(
			<Fragment>
				 <li><a href="#" onClick="#" data-toggle="modal" data-target="#responsive-modal">Add DKIM Record</a></li>
				 <li><a href="#" onClick="#" data-toggle="modal" data-target="#responsive-modal">Update DKIM Record</a></li>
				 <li><a href="#" onClick="#" data-toggle="modal" data-target="#responsive-modal">Delete DKIM Record</a></li>
		 	</Fragment>
		 )
	 }else if(readonlymenu){
		 return (
				 <Fragment />
		 )
	 }
 }
 renderlivefeedmenuitems(){
	 
	 
	 if(this.wsconnectionactive()||this.state.wsisconnecting){
	 	 return(
			<Fragment>
				 <li><a href="#" onClick={this.connectlivefeed} data-toggle="modal" data-target="#responsive-modal">{(this.wsconnectionactive())?'Reconnect':'Connect'}</a></li>
				 <li><a href="#" onClick={this.closeactivewssubscriptions} data-toggle="modal" data-target="#responsive-modal">Disconnect</a></li>
				 <li><a href="#" onClick={(e) => this.exportwsmessages('',e)}  data-toggle="modal" data-target="#responsive-modal">Copy to Clipboard</a></li>
				 <li><a href="#" onClick={this.clearmessages} data-toggle="modal" data-target="#responsive-modal">Clear</a></li>
		 	</Fragment>
		 )
	 }else{
		 return(
		 	 <Fragment>
			 <li><a href="#" onClick={this.connectlivefeed} data-toggle="modal" data-target="#responsive-modal">Connect</a></li>
			 <li><a href="#" onClick={this.exportmessages} data-toggle="modal" data-target="#responsive-modal">Export</a></li>
			 </Fragment>
		 )
	 }
	
 }
 
 renderactionmenu(){

    switch(this.state.activesendertab){
        case "spf":
        	return ( 
        			<ul role="menu" class="dropdown-menu">
        				{this.renderspfupdatemenuitems(true)}{this.renderspfupdatemenuitems(false)} 
        			</ul>
        			)
	        
        break;
        case "dmarc":
	        return (
	            <ul role="menu" class="dropdown-menu">
	               { this.renderdmarcmenuitems(true)}{this.renderdmarcmenuitems(false)}
	            </ul>
	            );
        break;
        case "dkim":
	        return (
	            <ul role="menu" class="dropdown-menu">
	               { this.renderdkimmenuitems(true)}{this.renderdkimmenuitems(false)}
	            </ul>
	            );
        break;
        case "dns":
            return (
                <ul role="menu" class="dropdown-menu">
                	{this.renderdnsmenuitems(true)}{this.renderdnsmenuitems(false)}
                </ul>
                );
            break;
        case "audit":
        	 return (
                     <ul role="menu" class="dropdown-menu">
                        {this.renderaudittrailmenuitems(true)}{this.renderaudittrailmenuitems(false)}
                     </ul>
                     );
        	break;
        case "livefeed":
       	 return (
                    <ul role="menu" class="dropdown-menu">
                       {this.renderlivefeedmenuitems()}
                    </ul>
                    );
       	    break;
       case "home":
        return (
            <ul role="menu" class="dropdown-menu">
               {this.renderselecteddomainmenuitems(true)}{this.renderselecteddomainmenuitems(false)}
            </ul>
            );
        break;
        
    }
 }

 /*
   * renderIncludesList - function obtains includedomains from state and renders them in the AddSender Modal dialogue
  */
  renderIncludesList() {
    var myincludes = this.state.includedomains;

    if(myincludes.length>0){
        return [{}].concat(myincludes).map(
            (include, i) =>
            i !== 0
                ?   <ListGroupItem key={include}>
                         <div class="form-group">
			             	<div class="pull-right"><button value={include} className="btn btn-warning" onClick={(e) => this.handleDeleteRow(include, e)}>Remove</button></div>
			             	<label class="control-label mb-10 domaininclude">{include}</label>
			             </div>
                    </ListGroupItem>
                : <ListGroupItem>
                  </ListGroupItem>
        );
    }else{
        return;
    }
  }

  renderDkimList(){
	  var dkeys = this.state.domainkeys;

	    if(dkeys.length>0){
	        return [{}].concat(dkeys).map(
	            (dkey, i) =>
	            i !== 0
	                ?   <ListGroupItem key={dkey} header={dkey}>
	                         <button value={dkey} className="btn btn-warning" onClick={(e) => this.handleDeleteRow(dkey, e)}>Remove</button>
	                    </ListGroupItem>
	                : <ListGroupItem>
	                  </ListGroupItem>
	        );
	    }else{
	        return;
	    }
  }
  
  renderDkimKeys(keys){
  
  		return [{}].concat(keys).map(
			    (k, i) =>
			      i !== 0 && k
			        ? 
			            <ListGroupItem>
			             <div class="form-group">
			             	<div class="pull-right"><button value={k} className="btn btn-warning" onClick={(e) => this.handleDeleteRow(k, e)}>Remove</button></div>
			             	<label class="control-label mb-10">Key:</label>
			             	<div class="dkimkeyname"> {k.keyname} </div>
			             	<label class="control-label mb-10">Type:</label>
			                <div class="dkimkeytype"> {k.type} </div>
			                <label class="control-label mb-10">Value:</label>
			                <div class="dkimkeyvalue"> {k.value} </div>
			            </div>
			            </ListGroupItem>
			          
			        :   <Fragment />
			          
			  );
  
  }

 /*handleDeleteRow = async(k, e)=>{
 	
 	var crrdkeys = this.state.selecteddomainkeys;
 	crrdkeys.splice(k,1);
 	this.setActiveState({selecteddomainkeys:crrdkeys});

 }*/
/*
   * handleAddIncludeDomain - function validates domain name entered references valid records and adds accordingly
  */
  handleAddIncludeDomain = async (event) =>{
      event.preventDefault();
      
    this.setState({ isLoading: true });
     
    var domain =  this.state.txtincludedomain;//this.state.txtincludedomain
    
    if(await this.isvalidspfrecorddomain(domain)){
       
        var domains = this.state.includedomains.concat([domain]);
        
        
        this.setState({includedomains:domains});
        this.setState({txtincludedomain:""});
        
    }else{

        this.setState({modalmsg:'Domain name does not reference a valid SPF record.  Specify a domain with a valid SPF reference.'});
    }

    this.setState({ isLoading: false });


  }

  /*
   * isvalidspfrecorddomain - validates the referenced domain name is valid, and references a valid SPF record usng spfxdns library.
   */
  async isvalidspfrecorddomain(domain){
    
    //return (domain.length > 0);
    var result = await this.vspfx.verifySpf(domain);
    
    //alert(JSON.stringify(result.domaintest));
    return result.valid
  }




  resetAllSettings(scope){
      switch(scope){
          case "all":
          this.setState(
              {
                  rdodefaultspfauthpolicy:this.state.selecteddomain.defaultSpfPolicy
                , rdosourceauditing:this.state.selecteddomain.defaultSourceAuditing
                , rdoipindexing:this.state.selecteddomain.enableIpIndexing
                , rdoemailcapture:this.state.selecteddomain.enableEmailCapture
                , rdodefaultdmarcaction:this.state.selecteddomain.defaultDmarcPolicy
              }
          );
          break;
          case "spf":
               this.setState(
              {
                  rdodefaultspfauthpolicy:this.state.selecteddomain.defaultSpfPolicy
                , rdosourceauditing:this.state.selecteddomain.defaultSourceAuditing
                , rdoipindexing:this.state.selecteddomain.enableIpIndexing
                , rdoemailcapture:this.state.selecteddomain.enableEmailCapture
               
              }
          );
          break;
          case "dmarc":
                this.setState(
              {
                  rdodefaultdmarcaction:this.state.selecteddomain.defaultDmarcPolicy
                  , txtrualist:this.state.selecteddomain.dmarcRua
                  , txtruflist:this.state.selecteddomain.dmarcRuf
                  , txtdmarcPct:this.state.selecteddomain.dmarcPct
              }
          );
          break;
      }
    
      
  }
  
  copyDnsToClipboard(d){
	   //this.taDnsRecords.select();
	   //document.execCommand('copy');
	  
	   var dnsrecords = this.state.dnsdata;
	   
	   var hdr = {};
	       hdr.Name="Host";
	  	   hdr.Type ="Type";
	  	   hdr.TTL = "TTL";
	  	   hdr.Value = "Value";
	  
	   var dtout = [hdr];
	   dtout = dtout.concat(dnsrecords)
	   
	   
	   var textField = document.createElement('textarea')
	   textField.innerHTML = this.buildplaintextdnsrecords(dtout);
	   document.body.appendChild(textField)
	   textField.select()
	   document.execCommand('copy')
	   textField.remove()
	   
	   
	   
	   //e.target.focus();
	   this.setState({ msg: 'DNS Records copied to clipboard.' });
  }
  
  copyAuditTrailsToClipboard(d){
	   //this.taDnsRecords.select();
	   //document.execCommand('copy');
	  
	   var audittrails = this.state.audittrails
	   
	   var hdr = {};
	       hdr.actiondate="Date";
	  	   hdr.src ="Source";
	  	   hdr.action = "Action";
	  	   hdr.emailaddress = "User";
	  	   hdr.configs = "Configuration"
	  
	   var dtout = [hdr];
	   dtout = dtout.concat(audittrails)
	   
	   
	   var textField = document.createElement('textarea')
	   textField.innerHTML = this.buildplaintextaudittrails(dtout);
	   document.body.appendChild(textField)
	   textField.select()
	   document.execCommand('copy')
	   textField.remove()
	   
	   //e.target.focus();
	   this.setState({ msg: 'Audit trails copied to clipboard.' });
 }
 
 

  showdomain= async (domain,rowindex, event) => {
	
	//close any active ws subscriptions on domain change
	this.closeactivewssubscriptions();
  
  	
	        
	        
    //populate panel with domain details
    if((this.state.selecteddomain.defaultSpfPolicy != this.state.rdodefaultspfauthpolicy) ||
        (this.state.selecteddomain.defaultSourceAuditing != this.state.rdosourceauditing) ||
        (this.state.selecteddomain.enableEmailCapture != this.state.rdoemailcapture)||
        (this.state.selecteddomain.enableIpIndexing != this.state.rdoipindexing) ||
        (this.state.selecteddomain.defaultDmarcPolicy != this.state.rdodefaultdmarcaction)){
            
            this.setState({showalert:true});
        
        }else{
	        
	        var sdata = await this.spfxd.getauthorizedsenders(domain.spfxio_id);
	        var nameservers = {};
	        try{
	       	 
	          nameservers= await this.spfxd.getdomainkeynameservers(domain.spfxio_id);
	         
	         }catch(nserr){
	          nameservers=nserr;
	         }
  		   
	        
	        var ssenderdomain = (sdata)?(sdata.length>0)?sdata[0]:'':'';
	        this.setState({   selecteddomain:domain
				            , rdodefaultspfauthpolicy:domain.defaultSpfPolicy
				            , rdosourceauditing:domain.defaultSourceAuditing
				            , rdoemailcapture:domain.enableEmailCapture
				            , rdoipindexing:domain.enableIpIndexing
				            , rdoenablerelay:domain.enableRelayServices
	                        , rdodefaultdmarcaction:domain.defaultDmarcPolicy
	                        , txtdmarcPct:domain.dmarcPct*100
	                        , txtrualist:domain.dmarcRua
	                        , txtruflist:domain.dmarcRuf
	                        , selectedrow:rowindex
	                        , selecteddomainsenders:sdata
	                        , selectedsenderdomain:ssenderdomain
	                        , selectedsenderdkimns:nameservers
	                        , selectedsenderrow:1
	                        , dnsdata:[]
	        				, msg:''
	                    });
	        
	        
			if(this.state.activesendertab=='dns'){
				var t = await this.sendertabclick('dns',null)
			}
			
			if(this.state.activesendertab=='audit'){
				await this.setaudittrails(domain.spfxio_id);
			}
			
        }
    
  }

  
  showsenderdomain= async (domain,rowindex, event) => {
    //populate panel with domain details
     this.setActiveState({selectedsenderrow:rowindex,selectedsenderdomain:domain, msg:'' });

    
  }

confirmDeleteSender(){
	this.setState({showconfirmeddeletealert:true});
	
}
async deleteSender(){
    
    var selecteddomain = this.state.selectedsenderdomain
    var senders = this.state.selecteddomainsenders
    var selindex = senders.indexOf(selecteddomain);
    
    var d = selecteddomain;
    var pparams = {};
    pparams.spfx = {
             spfxio_id: this.state.selecteddomain.spfxio_id  //spfxio hosted domain id
           , domain: this.state.selecteddomain.domain
           , senderdomain: d.senderdomain
           , includehosts:  d.includes //must conform to dns formatted names
           , defaultSpfPolicy:  d.defaultSpfPolicy//[~||-||?]
           , defaultSourceAuditing: d.defaultSourceAuditing //([true || false] as bit
           , enableEmailCapture : d.enableEmailCapture
           
    };

    
    var res = await this.spfxd.deleteauthorizedsender(pparams);
    
    if(res.err){
    	this.setActiveState({msg: "error processing delete", showconfirmeddeletealert: false});
    }else{
    
	    //senders.splice(senders.indexOf(selecteddomain),1);
	    senders.splice(selindex,1);
	    
	    
	    
	    if(selindex>=senders.length){
	    	selindex=senders.length-1;
	    }
	    
	    this.setState({selecteddomainsenders:senders,selectedsenderdomain:senders[selindex], msg: "Sending Domain Deleted Successfully", showconfirmeddeletealert: false});
    }

}

cancelDkimSave(origdomain){
	var selecteddomain = this.state.selectedsenderdomain
    var senders = this.state.selecteddomainsenders
    var selindex = senders.indexOf(selecteddomain);
    
    senders[selindex].dkimkeys=origdomain.dkimkeys;
    this.setState({selecteddomainsenders:senders});
    
}

renderDomains(domains) {
  return [{}].concat(domains).map(
    (domain, i) =>
      i !== 0
        ?   <NavItem  className="mt-5">
                <ListGroupItem header={domain.domain} className={(i===this.state.selectedrow)?'active':''} onClick={(e) => this.showdomain(domain,i, e)}>
                        {/*<span className={(domain.status==='verified')?'label label-table label-success': 'label label-table label-danger'}>{domain.status}</span>*/}
                        {/*<span class="peity-bar" data-width="90" data-peity='{ "fill": ["#ed6f56"], "stroke":["#ed6f56"]}' data-height="40">{domain.stats.join(',')}</span>*/}
                        {/*<span class="text-success text-semibold"><i class="fa fa-level-up" aria-hidden="true"></i> { domain.senders.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") }</span>*/}
                </ListGroupItem>
            </NavItem>
         : ''
  );
}

renderNS(nameservers){
	try{
	if(nameservers){
		
		var res = []
		for(var nameserver of nameservers)
		{
			if(nameserver.ResourceRecords)
			{
				for(var rec of nameserver.ResourceRecords)
				{
					res.push(rec.Value);	
				}
			}
		}
		return [{}].concat(res).map(
			(v,i)=>
			i !== 0
				? <div>{v}</div>
				: ''
		
		);
	
	}else{
		return [{}]
	}
	return [{}].concat(nameservers).map(
		(nameserver,i)=>
		i !== 0
			? <div>{ JSON.stringify(nameserver.ResourceRecords) }</div>
			: ''
	);
	}catch(err){
		return [];
	}
}

renderKeys(keys){
	var vals =  [{}].concat(keys).map((key, i) => (i !==0 && key)?key.keyname:'');
	vals.shift();
	return vals.join(',');
}

renderSenders(domains) {
  return [{}].concat(domains).map(
    (domain, i) =>
      i !== 0
        ?   <NavItem  className="mt-5">
                <ListGroupItem header={domain.senderdomain} className={(i===this.state.selectedsenderrow)?'active':''} onClick={(e) => this.showsenderdomain(domain,i, e)}>
                        <div class="spfsenderdetail">
	                		<div class="spfsenderdetailitem"><div class="pull-left"><label>default SPF policy:</label></div><div class="pull-right">{this.translatespf(domain.defaultSpfPolicy)}</div><div class="clearfix"></div></div>
	                        <div class="spfsenderdetailitem"><div class="pull-left"><label>Source Auditing:</label></div><div class="pull-right">{this.translatesourceauditing(domain.defaultSourceAuditing)}</div><div class="clearfix"></div></div>
	                        <div class="spfsenderdetailitem"><div class="pull-left"><label>Email Address Capture:</label></div><div class="pull-right">{this.translateemailcapture(domain.enableEmailCapture)}</div><div class="clearfix"></div></div>
	                        <div class="spfsenderdetailitem"><div class="pull-left"><label>Include Reference Domains:</label></div><div class="pull-right">{domain.includes.join(',')}</div><div class="clearfix"></div></div>
	                        <div class="spfsenderdetailitem"><div class="pull-left"><label>DKIM Keys:</label></div><div class="pull-right">{this.renderKeys(domain.dkimkeys)}</div><div class="clearfix"></div></div>
                        </div>
                </ListGroupItem>
            </NavItem>
         : ''
  );
}

translatespf(policy){
	switch(policy){
		case '-':
			return 'fail';
		break;
		case '~':
			return 'softfail';
		break;
		case '?':
			return 'neutral';
		break;
	}
}

translatesourceauditing(policy){
	if(policy){
		switch(policy.toString()){
			case '2':
				return 'all unauthorized sources';
				break;
		
			case '1':
				return 'spf failures only';
				break;
			default:
				return 'disabled';
				break;
		}
	}else{
		return 'disabled';
	}
}
translateemailcapture(policy){
	if(policy){
		switch(policy.toString()){
			case '1':
				return 'enabled';
				break;
			default:
				return 'disabled';
				break;
		}
	}else{
		return 'disabled';
	}
}

renderConfirmDelete(){
	return (
			
		      <SweetAlert
		        show={this.state.showconfirmeddeletealert}
		        title="Are you sure?"
		        text="You are about to delete the selected authorized sender."
		        type="warning"
		        showCancelButton="true"
		        confirmButtonColor="#f0c541"
		        confirmButtonText = "Delete Now"
		        closeOnConfirm="false"
		        onCancel={() => this.setState({ showconfirmeddeletealert: false })}
		        onConfirm={() => this.deleteSender()}
		        />
		    
		  );
}

rendersweetalert() {
  return (
    
      <SweetAlert
        show={this.state.showalert}
        title="Are you sure?"
        text="We detected a configuration change.  Would you like to save before switching domains?"
        type="warning"
        showCancelButton="true"
        confirmButtonColor="#f0c541"
        confirmButtonText = "Save Now"
        closeOnConfirm="false"
        onCancel={() => this.setState({ showalert: false })}
        onConfirm={() => this.updateSenderSettings()}
        />
    
  );
}

  
	render() {

		var audittrailcolumns =  
		            [
		              {
		                  Header:'Date',
		                  accessor: 'actiondate'
		              }  
		              , {
		                  Header:'Source',
		                  accessor: 'src'
		              }
		              , {
		                  Header:'Action',
		                  accessor: 'action'
		              } 
		              , {
		                  Header:'User',
		                  accessor: 'emailaddress'
		              }
		            ];
		
		var livefeedcolumns = 
            	[
	              {
	                  id:'dtstamp',
	                  Header:'Date',
	                  accessor: row => new Date(row.timestamp).toString()
	              }  
	              , {
	                  Header:'Class',
	                  accessor: 'message.recclass'
	              }
	              , {
	                  Header:'Smtp Host Domain',
	                  accessor: 'message.smtphostdomain'
	              } , {
	                  Header:'Smtp IP',
	                  accessor: 'message.smtphostip'
	              }, {
	            	  Header:'Email Address Domain',
	            	  accessor: 'message.senderdomain'
	              } , {
	            	  Header:'Email Address',
	            	  accessor: 'message.senderaddress'
	              }
	              
	              
	            ];


		   
    return (
            <Fragment>

               

				<TopNav msg={this.state.msg} />
     			<LeftNav />
				<RightNav />
				<div className="page-wrapper">
                    <form>
                    <div className="container-fluid">
						
						
                      
					
                       {/* <!-- Row -->*/}
                        <div className="row">
                             <div className="col-lg-4 col-sm-4 domainbox">
                                <div className="panel panel-default card-view registereddomaincontainer">
                                    
                                    <div className="panel-wrapper collapse in">
                                    <div class="panel-heading">
                                                <div class="pull-left">
                                                    <h6 class="panel-title txt-dark">Registered Domains</h6>
                                                </div>
                                                <div class="pull-right">
                                                   <div class="btn-group">
														<div class="dropdown">
															<button aria-expanded="false" data-toggle="dropdown" class="btn btn-primary dropdown-toggle" type="button">Action Menu<span class="caret"></span></button>
															<ul role="menu" class="dropdown-menu">
																{this.renderdomainmenuitems(true)}{this.renderdomainmenuitems(false)}
															</ul>
														</div>
													</div>
                                                </div>
                                                <div class="clearfix"></div>
                                            </div>
                                        <div className="panel-body">
                                            <div class="table-wrap mt-0">
										        <div className="table-responsive scroll-panel domaincontainer">
                                                       <ListGroup >
                                                        { !(this.state.domaindata.length===0) && this.renderDomains(this.state.domaindata) }
                                                        </ListGroup>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>	
                            </div>
                            <div className="col-lg-8 col-sm-8 spfbox">

                                <div class="panel panel-default card-view selecteddomaincontainer">
                                  
                                    <div class="panel-wrapper collapse in">
                                        <div class="panel-body">
                                            <div class="pull-right">
                                                <div class="btn-group">
													<div class="dropdown">
                                                        <button aria-expanded="false" data-toggle="dropdown" class="btn btn-primary dropdown-toggle" type="button">Action Menu<span class="caret"></span></button>
												        
                                                            {this.renderactionmenu()}
                                                           
                                                    </div>
                                                </div>
                                            </div>
                                            <div  class="tab-struct nav-tab custom-tab-2 mt-0">
                                                <ul role="tablist" class="nav nav-tabs" id="myTabs_16">
                                                    <li class={(this.state.activesendertab==='home')?'active':''} role="presentation"><a aria expanded="true"  data-toggle="tab" role="tab" id="home_tab_domain" href="#home_domain" onClick={(e) => this.sendertabclick('home',e)}>{this.state.selecteddomain.domain}</a></li>
                                                    <li class={(this.state.activesendertab==='spf')?'active':''} role="presentation"><a  data-toggle="tab" role="tab" id="home_tab_spf" href="#home_spf" onClick={(e) => this.sendertabclick('spf',e)}>Authorized Senders</a></li>
                                                    <li class={(this.state.activesendertab==='dmarc')?'active':''} role="presentation"><a  data-toggle="tab" id="home_tab_dmarc" role="tab" href="#home_dmarc" aria-expanded="false" onClick={(e) => this.sendertabclick('dmarc',e)}>DMARC</a></li>
                                                    <li class={(this.state.activesendertab==='audit')?'active':''} role="presentation"><a  data-toggle="tab" id="profile_tab_audit" role="tab" href="#profile_audit" aria-expanded="false" onClick={(e) => this.sendertabclick('audit',e)}>Audit Trail</a></li>
                                                    <li class={(this.state.activesendertab==='dns')?'active':''} role="presentation"><a  data-toggle="tab" id="profile_tab_dns" role="tab" href="#profile_dns" aria-expanded="false" onClick={(e) => this.sendertabclick('dns',e)}>DNS Records</a></li>
                                                    {<li class={(this.state.activesendertab==='livefeed')?'active':''} role="presentation"><a  data-toggle="tab" id="profile_tab_livefeed" role="tab" href="#profile_livefeed" aria-expanded="false" onClick={(e) => this.sendertabclick('livefeed',e)}>Live Feed</a></li>}
                                                </ul>
                                                <div class="clearfix"></div>
                                                <div class="tab-content" id="myTabContent_16">
                                                    <div  id="home_domain" class="tab-pane fade active in" role="tabpanel">
                                                            <div class="clearfix"></div>
                                                            <hr class="light-grey-hr"/>
                                                            <div class="table-wrap mt-0">
                                                             <div class="form-group">
                                                                Create the following DNS records to enable spfXio for your domain::
                                                                	<table class="domandnsrecords">
		                                                    			<tr>
		                                                        			<th>Host</th>
		                                                        			<th>Type</th>
		                                                        			<th>Value</th>
		                                                        		</tr>
		                                                        		<tr>
		                                                        			<td>{this.state.selecteddomain.domain}</td> 
		                                                        			<td>TXT</td>
		                                                        			<td>
		                                                                        <code>
		                                                                            v=spf1 redirect=%{'{h2}'}.inc.%{'{d2}'}.{this.state.selecteddomain.spfxio_id}.{CONFIG.spfx.servicedomain}
		                                                                        </code>
		                                                                    </td>
		                                                                </tr>
		                                                                <tr>
		                                                        			<td>_dmarc.{this.state.selecteddomain.domain}</td> 
		                                                        			<td>CNAME</td>
		                                                        			<td>
		                                                                        <code>
		                                                                        	_dmarc.{this.state.selecteddomain.spfxio_id}.{CONFIG.spfx.servicedomain}
		                                                                        </code>
		                                                                    </td>
		                                                                </tr>
		                                                                <tr>
		                                                                	<td>_domainkey.{this.state.selecteddomain.domain}</td>
		                                                                	<td>NS</td>
		                                                                	<td>
		                                                                		<code>
		                                                                			{ this.renderNS(this.state.selectedsenderdkimns) }
		                                                                		</code>
		                                                                	</td>
		                                                                </tr>
		                                                            </table>

                                                                    {/*
	                                                                    <div class="row mt-30">
	                                                                        <div class="col-lg-4">
	                                                                            <div class="panel panel-default card-view">
	                                                                                <div class="panel-heading">
	                                                                                    <div class="pull-left">
	                                                                                        <h6 class="panel-title txt-dark">bar Chart</h6>
	                                                                                    </div>
	                                                                                    <div class="clearfix"></div>
	                                                                                </div>
	                                                                                <div class="panel-wrapper collapse in">
	                                                                                    <div class="panel-body">
	                                                                                        <canvas id="chart_2" height="200"></canvas>
	                                                                                    </div>	
	                                                                                </div>
	                                                                            </div>
	                                                                        </div>
	                                                                    </div>
	                                                                 */
																	}


                                                                    
                                                                    
                                                             </div>
                                                            </div>
                                                    </div>
                                                    <div  id="home_spf" class="tab-pane fade" role="tabpanel">
                                                            
                                                            <div class="clearfix"></div>
                                                    
                                                            <div class="panel-wrapper collapse in">
	                        									<div class="panel-body">
	                        										<div class="panel-group accordion-struct accordion-style-1" id="accordion_1" role="tablist" aria-multiselectable="true">
	                        											<div class="panel panel-default">
	                        												<div class="panel-heading" role="tab" id="heading_1">
	                        													<a role="button" data-toggle="collapse" data-parent="#accordion_1" href="#collapse_1" aria-expanded="false"><div class="icon-ac-wrap pr-20"><span class="plus-ac"><i class="ti-plus"></i></span><span class="minus-ac"><i class="ti-minus"></i></span></div>Domain SPF Policies</a> 
	                        												</div>
	                        												<div id="collapse_1" class="panel-collapse collapse" role="tabpanel">
	                        													<div class="panel-body pa-15">
			                        													<div class="table-wrap mt-0">
			                                                                            
			                                                                            <div class="col-md-4  mt-25" >
			                                                                            	
			                                                                                <div class="form-group">
				                                                                                <label class="control-label mb-10"><div class="tooltiphelp"><i class="zmdi zmdi-star-circle mr-10"></i><span class="tooltiphelptext">Control the default SPF authentication response for sending domains which are not explicitly authorized.</span>default SPF policy</div></label>
			                                                                                    <div class="radio-list">
			                                                                                        <div class="radio-inline pl-20">
			                                                                                            <span class="radio radio-info">
			                                                                                                <input type="radio" name="rdodefaultspfauthpolicy" id="rdodefaultspfauthpolicyneutral" value="?"  onChange={this.handleRdoChange}   checked={this.state.rdodefaultspfauthpolicy=='?'} defaultChecked={this.state.rdodefaultspfauthpolicy=='?'} disabled={this.state.readonlyview}  />
			                                                                                            <label htmlFor="rdodefaultspfauthpolicyneutral">neutral</label>
			                                                                                            </span>
			                                                                                            <span class="radio radio-info">
			                                                                                            <input type="radio" name="rdodefaultspfauthpolicy" id="rdodefaultspfauthpolicysoftfail" value="~"  onChange={this.handleRdoChange} checked={this.state.rdodefaultspfauthpolicy=='~'} defaultChecked={this.state.rdodefaultspfauthpolicy=='~'} disabled={this.state.readonlyview}  />
			                	                                                                        <label htmlFor="rdodefaultspfauthpolicysoftfail">soft fail</label>
			                	                                                                        </span>
			                	                                                                        <span class="radio radio-info">
			                	                                                                            <input type="radio" name="rdodefaultspfauthpolicy" id="rdodefaultspfauthpolicyfail" value="-"  onChange={this.handleRdoChange}  checked={this.state.rdodefaultspfauthpolicy=='-'} defaultChecked={this.state.rdodefaultspfauthpolicy=='-'} disabled={this.state.readonlyview} />
			                	                                                                        <label htmlFor="rdodefaultspfauthpolicyfail">fail</label>
			                	                                                                        </span>
			                                                                                        </div>
			                                                                                    </div>
			                                                                                </div>
			                                                                            </div>
			                                                                            <div class="col-md-4 mt-25">
			                	                                                            <div class="form-group">
			                	                                                                <label class="control-label mb-10"><div class="tooltiphelp"><i class="zmdi zmdi-link mr-10"></i><span class="tooltiphelptext">IP indexing authenticates the sending IP address where the sending domain is not explitly authorized.  For example, mail security filtering domains may not be explicitly authorized and may therefore be authenticated via IP indexing.</span>IP indexing</div></label>
			                	                                                                <div class="radio-list">
			                	                                                                    <div class="radio-inline pl-20">
			                	                                                                        <span class="radio radio-info">
			                		                                                                        <input type="radio" name="rdoipindexing" id="rdoipindexingdisabled" value="0"  onChange={this.handleRdoChange} checked={this.state.rdoipindexing=='0'} defaultChecked={this.state.rdoipindexing=='0'} disabled={this.state.readonlyview} />
			                		                                                                    <label htmlFor="rdoipindexingdisabled">disabled</label>
			                		                                                                    </span>
			                		                                                                    <span class="radio radio-info">
			                	                                                                        <input type="radio" name="rdoipindexing" id="rdoipindexingenabled" value="1"  onChange={this.handleRdoChange} checked={this.state.rdoipindexing=='1'}defaultChecked={this.state.rdoipindexing=='1'} disabled={this.state.readonlyview} />
			                	                                                                    <label htmlFor="rdoipindexingenabled">enabled</label>
			                	                                                                    </span>
			                	                                                                    </div>
			                	                                                                </div>
			                	                                                            </div>
			                	                                                        </div>
			                	                                                        <div class="col-md-4 mt-25">
			                	                                                            <div class="form-group">
			                	                                                                <label class="control-label mb-10"><div class="tooltiphelp"><i class="zmdi zmdi-eye mr-10"></i><span class="tooltiphelptext">Source auditing settings allows you to enable an audit trail for all sending domains not explicitly authorized, or only when SPF authentication fails.</span>source auditing</div></label>
			                	                                                                <div class="radio-list">
			                	                                                                    <div class="radio-inline pl-20">
			                	                                                                        <span class="radio radio-info">
			                	                                                                        <input type="radio" name="rdosourceauditing" id="rdosourceauditingdisabled" value="0"  onChange={this.handleRdoChange} checked={this.state.rdosourceauditing=='0'} defaultChecked={this.state.rdosourceauditing=='0'}  disabled={this.state.readonlyview} />
			                	                                                                        <label htmlFor="rdosourceauditingdisabled">disabled</label>
			                	                                                                        </span>
			                	                                                                        <span class="radio radio-info">
			                	                                                                        <input type="radio" name="rdosourceauditing" id="rdosourceauditingenabled" value="1"  onChange={this.handleRdoChange} checked={this.state.rdosourceauditing=='1'}defaultChecked={this.state.rdosourceauditing=='1'}  disabled={this.state.readonlyview} />
			                	                                                                        <label htmlFor="rdosourceauditingenabled">spf failures only</label>
			                	                                                                        </span>
			                	                                                                        <span class="radio radio-info">
			                	                                                                        <input type="radio" name="rdosourceauditing" id="rdosourceauditingenabledall" value="2"  onChange={this.handleRdoChange} checked={this.state.rdosourceauditing=='2'}defaultChecked={this.state.rdosourceauditing=='2'}  disabled={this.state.readonlyview} />
			                	                                                                        <label htmlFor="rdosourceauditingenabled">all unauthorized sources</label>
			                	                                                                        </span>
			                	                                                                    </div>
			                		                                                             </div>
			                		                                                        </div>
			                	                                                        </div>
			                	                                                        <div class="col-md-4 mt-25">
			                	                                                            <div class="form-group">
			                	                                                                <label class="control-label mb-10"><div class="tooltiphelp"><i class="zmdi zmdi-email mr-10"></i><span class="tooltiphelptext">Email Address Capture enables logging of the email sending address when source auditing is enabled.</span>Email Address Capture</div></label>
			                	                                                                <div class="radio-list">
			                	                                                                    <div class="radio-inline pl-20">
			                	                                                                        <span class="radio radio-info">
			                	                                                                        <input type="radio" name="rdoemailcapture" id="rdoemailcapturedisabled" value="0"  onChange={this.handleRdoChange} checked={this.state.rdoemailcapture=='0'} defaultChecked={this.state.rdoemailcapture=='0'}  disabled={this.state.readonlyview} />
			                	                                                                        <label htmlFor="rdoemailcapturedisabled">disabled</label>
			                	                                                                        </span>
			                	                                                                        <span class="radio radio-info">
			                	                                                                        <input type="radio" name="rdoemailcapture" id="rdoemailcaptureenabled" value="1"  onChange={this.handleRdoChange} checked={this.state.rdoemailcapture=='1'}defaultChecked={this.state.rdoemailcapture=='1'}  disabled={this.state.readonlyview} />
			                	                                                                        <label htmlFor="rdoemailcaptureenabled">enabled</label>
			                	                                                                        </span>
			                	                                                                    </div>
			                		                                                             </div>
			                		                                                        </div>
			                	                                                        </div>
			                	                                                        <div class="col-md-4 mt-25">
			                	                                                            <div class="form-group">
			                	                                                                <label class="control-label mb-10"><div class="tooltiphelp"><i class="zmdi zmdi-mail-reply-all mr-10"></i><span class="tooltiphelptext">Enable to produce neutral SPF authentication results for relay services forwarding on behalf of your domain.  Such services may include mailing-lists, inbox forwarding rules, or email filtering platforms.  Enabling relay services may minimize SPF failures associated with legitimate email use.</span>Enable Relay Services</div></label>
			                	                                                                <div class="radio-list">
			                	                                                                    <div class="radio-inline pl-20">
			                	                                                                        <span class="radio radio-info">
			                	                                                                        <input type="radio" name="rdoenablerelay" id="rdoenablerelaydisabled" value="0"  onChange={this.handleRdoChange} checked={this.state.rdoenablerelay=='0'} defaultChecked={this.state.rdoenablerelay=='0'}  disabled={this.state.readonlyview} />
			                	                                                                        <label htmlFor="rdoenablerelaydisabled">disabled</label>
			                	                                                                        </span>
			                	                                                                        <span class="radio radio-info">
			                	                                                                        <input type="radio" name="rdoenablerelay" id="rdoenablerelayenabled" value="1"  onChange={this.handleRdoChange} checked={this.state.rdoenablerelay=='1'}defaultChecked={this.state.rdoenablerelay=='1'}  disabled={this.state.readonlyview} />
			                	                                                                        <label htmlFor="rdoenablerelayenabled">enabled</label>
			                	                                                                        </span>
			                	                                                                    </div>
			                		                                                             </div>
			                		                                                        </div>
			                	                                                        </div>
			                                                                        </div>
	                        												
	                        												
	                        												
	                        													</div>
	                        												</div>
	                        											</div>
	                        											<div class="panel panel-default">
	                        												<div class="panel-heading" role="tab" id="heading_2">
	                        													<a class="collapsed" role="button" data-toggle="collapse" data-parent="#accordion_1" href="#collapse_2" aria-expanded="true"><div class="icon-ac-wrap pr-20"><span class="plus-ac"><i class="ti-plus"></i></span><span class="minus-ac"><i class="ti-minus"></i></span></div>Authorized Sending Domains</a>
	                        												</div>
	                        												<div id="collapse_2" class="panel-collapse collapse" role="tabpanel">
	                        													<div class="panel-body pa-15">
	                        													  <div className="senderlistcontainer col-md-12 mt-15">
		                                                                              <ListGroup className="">
		                                                                                  { !(this.state.selecteddomainsenders.length===0) && this.renderSenders(this.state.selecteddomainsenders) }
		                                                                              </ListGroup>
		                                                                          </div>
	                        													</div>
	                        												</div>
	                        											</div>
	                        											
	                        										</div>
	                        									</div>
	                        								</div>
                                                                                                                          
                                                        
                                                    </div>
                                                    <div  id="home_dkim" class="tab-pane fade " role="tabpanel">
                                                    	<p>manage dkim keys for your domain</p>
                                                    </div>
                                                    <div  id="home_dmarc" class="tab-pane fade" role="tabpanel">
                                                    
                                                            <div class="clearfix"></div>
                                                            <div>
	                                                            <div class="form-group">
	                                                                <label class="control-label mb-10"><i class="zmdi zmdi-star-circle mr-10"></i>DMARC policy</label>
	                                                                <div class="radio-list">
	                                                                    <div class="radio-inline pl-0">
	                                                                        <span class="radio radio-info">
	                                                                            <input type="radio" name="rdodefaultdmarcaction" id="rdodefaultdmarcactionnone" value="none"  onChange={this.handleRdoChange} checked={this.state.rdodefaultdmarcaction=='none'} defaultChecked={this.state.rdodefaultdmarcaction=='none'}  disabled={this.state.readonlyview} />
	                                                                        <label htmlFor="rdodefaultdmarcactionnone">none</label>
	                                                                        </span>
	                                                                    </div>
	                                                                    <div class="radio-inline">
	                                                                        <span class="radio radio-info">
	                                                                            <input type="radio" name="rdodefaultdmarcaction" id="rdodefaultdmarcactionquarantine" value="quarantine"  onChange={this.handleRdoChange} checked={this.state.rdodefaultdmarcaction=='quarantine'} defaultChecked={this.state.rdodefaultdmarcaction=='quarantine'}  disabled={this.state.readonlyview}  />
	                                                                        <label htmlFor="rdodefaultdmarcactionquarantine">quarantine</label>
	                                                                        </span>
	                                                                    </div>
	                                                                    <div class="radio-inline">
	                                                                        <span class="radio radio-info">
	                                                                            <input type="radio" name="rdodefaultdmarcaction" id="rdodefaultdmarcactionreject" value="reject"  onChange={this.handleRdoChange}  checked={this.state.rdodefaultdmarcaction=='reject'} defaultChecked={this.state.rdodefaultdmarcaction=='reject'}  disabled={this.state.readonlyview} />
	                                                                        <label htmlFor="rdodefaultdmarcactionreject">reject</label>
	                                                                        </span>
	                                                                    </div>
	                                                                </div>
	                                                            </div>
	                                                            <div class="form-group">
																	<label class="control-label mb-10" for="exampleInputEmail_01">Policy Application Percentage</label>
																	<div class="input-group">
																		<div class="input-group-addon"><i class="icon-speedometer"></i></div>
																		<input type="number" class="form-control" name="txtdmarcPct" id="txtdmarcPct" onChange={this.handleChange}  value={this.state.txtdmarcPct} disabled={this.state.readonlyview} />
																	</div>
																</div>
	                                                            <div class="form-group">
																	<label class="control-label mb-10" for="exampleInputEmail_01">RUA Address List</label>
																	<div class="input-group">
																		<div class="input-group-addon"><i class="icon-envelope-open"></i></div>
																		<input type="email" class="form-control" name="txtrualist" id="txtrualist" onChange={this.handleChange} value={this.state.txtrualist} disabled={this.state.readonlyview} />
																	</div>
																</div>
																 <div class="form-group">
																	<label class="control-label mb-10" for="exampleInputEmail_01">RUF Address List</label>
																	<div class="input-group">
																		<div class="input-group-addon"><i class="icon-envelope-open"></i></div>
																		<input type="email" class="form-control" name="txtruflist" id="txtruflist" onChange={this.handleChange} value={this.state.txtruflist} disabled={this.state.readonlyview} />
																	</div>
																</div>
	                                                            
                                                            </div>
                                                        
                                                    </div>
                                                    <div  id="profile_audit" class="tab-pane fade " role="tabpanel">
                                                    	<div className="dnsrecordscontainer">
		                                                        <ReactTable
		                                                        	showPagination="true"
		                                                        	data={this.state.audittrails}
		                                                        	columns={audittrailcolumns}
		                                                        	className="table table-hover table-bordered display mb-30"
		                                                        	id="datable_1"
		                                                        	defaultPageSize={17}
		                                                        	minRows = "5"
		                                                        	SubComponent={row=>{
		                                                        		return (
		                                                        				<div class="audittraildetail">{row.original.configs}</div>
		                                                        		)
		                                                        	
		                                                        	}}
		                                                        	
		                                                        	
		                                                        />
		                                                </div>
                                                    </div>
                                                    <div  id="profile_dns" class="tab-pane fade" role="tabpanel">
	                                                    <div className="dnsrecordscontainer">
	                                                    	<ListGroup className="">
                                                        		{ this.renderdnsrecords(this.state.dnsdata)  }
                                                        	</ListGroup>
                                                        	
                                                        	
                                                        </div>
                                                    </div>
                                                    
                                                    <div  id="profile_livefeed" class="tab-pane fade" role="tabpanel">
	                                                    <div className="livefeedcontainer">
	                                                    		
	                                                    		{/*<ListGroup className="">{this.renderlivefeed(this.state.livefeedmessages[this.state.selecteddomain.spfxio_id])}</ListGroup>*/}
	                                                    		 <ReactTable
		                                                        	showPagination="true"
		                                                        	data={this.state.livefeedmessages[this.state.selecteddomain.spfxio_id]}
		                                                        	columns={livefeedcolumns}
		                                                        	className="table table-hover table-bordered display mb-30"
		                                                        	id="datable_1"
		                                                        	defaultPageSize={17}
		                                                        	minRows = "17"
		                                                        	SubComponent={row=>{
			                                                        		return (
			                                                        				<div class="audittraildetail">{JSON.stringify(row.original.message)}</div>
			                                                        		)
			                                                        	
			                                                        	}}
		                                                        />
	                                                    	
	                                                    </div>
	                                                </div>
                                                    
                                                    
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/*<!-- /Row -->*/}

                        {/*<!-- Add Sending Domain Modal-->*/}
                        <Modal id="responsive-modal"
                        		dialogClassName="addauthsendermodal"
                        		className="modal fade"
                                show={this.state.showAddSenderModal}
                                onHide={this.closeModal}
                                animation={true}
                                bsSize="small">
                                <Modal.Header closeButton className="modal-header">
                                    <Modal.Title className="modal-title">SPF Authorized Sending Domains</Modal.Title>
                                </Modal.Header>
                                <Modal.Body  className="modal-body">
                                        <div id="modalmsg">
                                            { this.state.modalmsg }
                                        </div>
                                        <form>
                                            <div className="form-group">
                                                <label for="recipient-name" className="control-label mb-10">Sending Domain:</label>
                                                <input type="text" className="form-control" id="txtsendingdomain" onChange={this.handleTxtFieldChange} value={this.state.txtsendingdomain}  />
                                            </div>
                                            <div className="form-group">
                                                <label for="message-text" className="control-label mb-10">default SPF policy:</label>
                                                    <div class="radio-list">
                                                    <div class="radio-inline pl-0">
                                                        <span class="radio radio-info">
                                                            <input type="radio" name="rdoaddpolicy" id="rdoaddpolicyneutral" onChange={this.handleRdoChange} value="?" defaultChecked={this.state.rdoaddpolicy=='?'} />
                                                        <label htmlFor="rdoaddpolicyneutral">neutral</label>
                                                        </span>
                                                    </div>
                                                    <div class="radio-inline pl-0">
                                                        <span class="radio radio-info">
                                                            <input type="radio" name="rdoaddpolicy" id="rdoaddpolicysoftfail" onChange={this.handleRdoChange} value="~" defaultChecked={this.state.rdoaddpolicy=='~'}  />
                                                        <label htmlFor="rdoaddpolicysoftfail">soft fail</label>
                                                        </span>
                                                    </div>
                                                    <div class="radio-inline pl-0">
                                                        <span class="radio radio-info">
                                                            <input type="radio" name="rdoaddpolicy" id="rdoaddpolicyfail" onChange={this.handleRdoChange} value="-" defaultChecked={this.state.rdoaddpolicy=='-'} />
                                                        <label htmlFor="rdoaddpolicyfail">fail</label>
                                                        </span>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="form-group">
                                               <label for="message-text" className="control-label mb-10">Source Auditing:</label>
                                                    <div class="radio-list">
                                                    <div class="radio-inline pl-0">
	                                                    <span class="radio radio-info">
	                                                        <input type="radio" name="rdoaddaudit" id="rdoaddauditdisabled" onChange={this.handleRdoChange} value="0" defaultChecked={this.state.rdoaddaudit=='0'}  />
	                                                    <label htmlFor="rdoaddauditdisabled">disabled</label>
	                                                    </span>
	                                                </div>
                                                    <div class="radio-inline pl-0">
                                                        <span class="radio radio-info">
                                                            <input type="radio" name="rdoaddaudit" id="rdoaddauditenabled" onChange={this.handleRdoChange} value="1" defaultChecked={this.state.rdoaddaudit=='1'} />
                                                        <label htmlFor="rdoaddauditenabled">spf failures only</label>
                                                        </span>
                                                    </div>
                                                    <div class="radio-inline pl-0">
                                                    <span class="radio radio-info">
                                                        <input type="radio" name="rdoaddaudit" id="rdoaddauditallsources" onChange={this.handleRdoChange} value="2" defaultChecked={this.state.rdoaddaudit=='2'} />
                                                    <label htmlFor="rdoaddauditallsources">all unauthorized sources</label>
                                                    </span>
                                                </div>
                                                    
                                                </div>
                                            </div>
                                            <div className="form-group">
                                            <label for="message-text" className="control-label mb-10">Email Address Capture:</label>
                                                 <div class="radio-list">
                                                 <div class="radio-inline pl-0">
                                                     <span class="radio radio-info">
                                                         <input type="radio" name="rdoaddemailcapture" id="rdoaddemailcaptureenabled" onChange={this.handleRdoChange} value="1" defaultChecked={this.state.rdoaddemailcapture=='1'} />
                                                     <label htmlFor="rdoaddemailcaptureenabled">enabled</label>
                                                     </span>
                                                 </div>
                                                 <div class="radio-inline">
                                                     <span class="radio radio-info">
                                                         <input type="radio" name="rdoaddemailcapture" id="rdoaddemailcapturedisabled" onChange={this.handleRdoChange} value="0" defaultChecked={this.state.rdoaddemailcapture=='0'}  />
                                                     <label htmlFor="rdoaddemailcapturedisabled">disabled</label>
                                                     </span>
                                                 </div>
                                             </div>
                                         </div>
                                            <div className="form-group">
                                                <div>
                                                    <label for="message-text" className="control-label mb-10">Include Reference Domains:</label>
                                                </div>
                                                <div>
                                                    
                                                    <FormGroup controlId="txtincludedomain">
                                                        <FormControl
                                                        onChange={this.handleTxtFieldChange}
                                                        value={this.state.txtincludedomain}
                                                        className="input"
                                                        
                                                        />
                                                    </FormGroup>
                                                    {/*<button type="button" >Add Include Domain</button>*/}
                                                        <LoaderButton
                                                            block
                                                            type="button"
                                                            text="Add Include"
                                                            disabled={!(this.state.txtincludedomain.length>0)}
                                                            isLoading={this.state.isLoading}
                                                            loadingText="validating..."
                                                            className="btn btn-warning"
                                                            onClick={(e) => this.handleAddIncludeDomain(e)} 
                                                        />
                                                        
                                                        
                                                </div>
                                                <ListGroup>
                                                {this.renderIncludesList()}
                                                 </ListGroup>
                                            
                                            </div>
                                        </form>
                                </Modal.Body>

                                <Modal.Footer>
                                                <LoaderButton
                                                        block
                                                        type="button"
                                                        text="Save changes"
                                                        loadingText="validating..."
                                                        isLoading={this.state.isvalidatingsenderecord}
                                                        className="btn btn-primary"
                                                        onClick={(e) => this.handleAddAuthorizedSender(e)} 
                                                        
                                                    />
                                                 <LoaderButton
                                                        block
                                                        type="button"
                                                        text="Cancel"
                                                        data-dismiss="modal"
                                                        className="btn btn-default"
                                                        onClick={this.closeModal}
                                                    />
                                </Modal.Footer>

                            </Modal>
                            
                            
                            {/*<!-- Add New Hosted Domain-->*/}
                            <Modal id="responsive-modal"
                            		dialogClassName="addnewdomainmodal"
                                    className="modal fade"
                                    show={this.state.showAddHostedDomainModal}
                                    onHide={this.closeModal}
                                    animation={true}
                                    bsSize="small">
                                    <Modal.Header closeButton className="modal-header">
                                        <Modal.Title className="modal-title">Add New Domain</Modal.Title>
                                    </Modal.Header>
                                    <Modal.Body  className="modal-body">
                                            <div id="modalmsg">
                                                { this.state.modalmsg }
                                            </div>
                                            <form>
                                                <div className="form-group">
                                                    <label for="recipient-name" className="control-label mb-10">Domain Name:</label>
                                                    <input type="text" className="form-control" id="txthosteddomain" onChange={this.handleTxtFieldChange} value={this.state.txthosteddomain}  />
                                                </div>
                                                <div className="form-group">
                                                    <label for="message-text" className="control-label mb-10">default SPF policy:</label>
                                                        <div class="radio-list">
                                                        <div class="radio-inline pl-0">
                                                            <span class="radio radio-info">
                                                                <input type="radio" name="rdoadddomainpolicy" id="rdoadddomainpolicyneutral" onChange={this.handleRdoChange} value="?" defaultChecked={this.state.rdoadddomainpolicy=='?'} />
                                                            <label htmlFor="rdoadddomainpolicyneutral">neutral</label>
                                                            </span>
                                                        </div>
                                                        <div class="radio-inline">
                                                            <span class="radio radio-info">
                                                                <input type="radio" name="rdoadddomainpolicy" id="rdoadddomainpolicysoftfail" onChange={this.handleRdoChange} value="~" defaultChecked={this.state.rdoadddomainpolicy=='~'}  />
                                                            <label htmlFor="rdoadddomainpolicysoftfail">soft fail</label>
                                                            </span>
                                                        </div>
                                                        <div class="radio-inline">
                                                            <span class="radio radio-info">
                                                                <input type="radio" name="rdoadddomainpolicy" id="rdoadddomainpolicyfail" onChange={this.handleRdoChange} value="-" defaultChecked={this.state.rdoadddomainpolicy=='-'} />
                                                            <label htmlFor="rdoadddomainpolicyfail">fail</label>
                                                            </span>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="form-group">
	                                                <label for="message-text" className="control-label mb-10">IP Indexing:</label>
	                                                     <div class="radio-list">
	                                                     
	                                                     <div class="radio-inline pl-0">
	                                                         <span class="radio radio-info">
	                                                             <input type="radio" name="rdoadddirectipindexing" id="rdoadddirectipindexingdisabled" onChange={this.handleRdoChange} value="0" defaultChecked={this.state.rdoadddirectipindexing=='0'}  />
	                                                         <label htmlFor="rdoadddirectipindexingdisabled">disabled</label>
	                                                         </span>
	                                                     </div>
	                                                     <div class="radio-inline">
	                                                         <span class="radio radio-info">
	                                                             <input type="radio" name="rdoadddirectipindexing" id="rdoadddirectipindexingenabled" onChange={this.handleRdoChange} value="1" defaultChecked={this.state.rdoadddirectipindexing=='1'} />
	                                                         <label htmlFor="rdoadddirectipindexingenabled">enabled</label>
	                                                         </span>
	                                                     </div>
	                                                     
	                                                 </div>
	                                             </div>
                                                <div className="form-group">
                                                   <label for="message-text" className="control-label mb-10">Source Auditing:</label>
                                                        <div class="radio-list">
                                                        <div class="radio-inline pl-0">
	                                                        <span class="radio radio-info">
	                                                            <input type="radio" name="rdoadddomainaudit" id="rdoadddomainauditdisabled" onChange={this.handleRdoChange} value="0" defaultChecked={this.state.rdoadddomainaudit=='0'}  />
	                                                        <label htmlFor="rdoadddomainauditdisabled">disabled</label>
	                                                        </span>
	                                                    </div>
                                                    	<div class="radio-inline">
                                                            <span class="radio radio-info">
                                                                <input type="radio" name="rdoadddomainaudit" id="rdoadddomainauditenabled" onChange={this.handleRdoChange} value="1" defaultChecked={this.state.rdoadddomainaudit=='1'} />
                                                            <label htmlFor="rdoadddomainauditenabled">spf failures only</label>
                                                            </span>
                                                        </div>
                                                        <div class="radio-inline">
	                                                        <span class="radio radio-info">
	                                                            <input type="radio" name="rdoadddomainaudit" id="rdoadddomainauditallenabled" onChange={this.handleRdoChange} value="2" defaultChecked={this.state.rdoadddomainaudit=='2'} />
	                                                        <label htmlFor="rdoadddomainauditallenabled">all unauthorized sources</label>
	                                                        </span>
	                                                    </div>
                                                        
                                                        
                                                        
                                                        
                                                    </div>
                                                </div>
                                                <div className="form-group">
	                                                <label for="message-text" className="control-label mb-10">Email Address Capture:</label>
	                                                     <div class="radio-list">
	                                                     <div class="radio-inline pl-0">
	                                                         <span class="radio radio-info">
	                                                             <input type="radio" name="rdoaddemailcapture" id="rdoaddemailcapturedisabled" onChange={this.handleRdoChange} value="0" defaultChecked={this.state.rdoaddemailcapture=='0'}  />
	                                                         <label htmlFor="rdoaddemailcapturedisabled">disabled</label>
	                                                         </span>
	                                                     </div>
                                                     	<div class="radio-inline">
	                                                         <span class="radio radio-info">
	                                                             <input type="radio" name="rdoaddemailcapture" id="rdoaddemailcaptureenabled" onChange={this.handleRdoChange} value="1" defaultChecked={this.state.rdoaddemailcapture=='1'} />
	                                                         <label htmlFor="rdoaddemailcaptureenabled">enabled</label>
	                                                         </span>
	                                                     </div>
	                                                     
	                                                 </div>
	                                             </div>
                                                
	                                             
                                                 
                                                 <div className="form-group">
	                                                <label for="message-text" className="control-label mb-10">Enable Relay Services:</label>
                                                     <div class="radio-list">
                                                         <div class="radio-inline pl-0">
                                                             <span class="radio radio-info">
                                                             <input type="radio" name="rdoaddenablerelay" id="rdoaddenablerelaydisabled" value="0"  onChange={this.handleRdoChange} checked={this.state.rdoaddenablerelay=='0'} defaultChecked={this.state.rdoaddenablerelay=='0'} />
                                                             <label htmlFor="rdoaddenablerelaydisabled">disabled</label>
                                                             </span>
                                                         </div>
                                                         <div class="radio-inline">
                                                             <span class="radio radio-info">
                                                             <input type="radio" name="rdoaddenablerelay" id="rdoaddenablerelayenabled" value="1"  onChange={this.handleRdoChange} checked={this.state.rdoaddenablerelay=='1'}defaultChecked={this.state.rdoaddenablerelay=='1'}  />
                                                             <label htmlFor="rdoaddenablerelayenabled">enabled</label>
                                                             </span>
                                                         </div>
                                                      </div>
                                                 </div>
                                                 
                                             
                                            </form>
                                    </Modal.Body>

                                    <Modal.Footer>
                                                    <LoaderButton
                                                            block
                                                            type="button"
                                                            text="Add Domain"
                                                            loadingText="validating..."
                                                            isLoading={this.state.isvalidatinghostedomain}
                                                            className="btn btn-primary"
                                                            onClick={(e) => this.handleAddHostedDomain(e)} 
                                                            
                                                        />
                                                     <LoaderButton
                                                            block
                                                            type="button"
                                                            text="Cancel"
                                                            data-dismiss="modal"
                                                            className="btn btn-default"
                                                            onClick={this.closeModal}
                                                        />
                                    </Modal.Footer>

                                </Modal>
                                
                                
                                <Domain_DKIM 
                                	showModal = { this.state.showDkimDomainModal }
                                	selectedDomain = {this.state.selecteddomain}
                                	selectedSender = {this.state.selectedsenderdomain}
                                	onclose = { this.closeModal }
                                	onkeyschanged = {(keys) => this.setState({selecteddomainkeys:keys}) }
                                	oncancel = {(origdomain) => this.cancelDkimSave(origdomain) }
                                	user = { this.spfxd.getuser() }
                                />



						<Footer />
                        { this.rendersweetalert() }
                        { this.renderConfirmDelete() }
					</div>
                    </form>
			    </div>
            </Fragment>
		
    );
  }
  
  
}