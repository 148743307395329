
import { Auth } from "aws-amplify";

import { FormGroup, FormControl, ControlLabel, Modal } from "react-bootstrap";

import Select from 'react-select';
//import { FormGroup, FormControl, ListGroup, ListGroupItem, Modal, Radio } from "react-bootstrap";
import React, { Fragment } from "react";
import LeftNav from "../components/LeftNav"
import TopNav from "../components/TopNav"
import RightNav from "../components/RightNav"
import Footer from "../components/Footer"
import BaseComponent from "../classes/BaseComponent"
import LoaderButton from "../components/LoaderButton"
import QRCode from "qrcode.react";
import SweetAlert from 'sweetalert-react';


import spfxdata from "../classes/spfxdata"

import "./profile.css"

export default class profile extends BaseComponent {
	constructor(props) {
    super(props);

    this.state = {
      isLoading: false
      ,msg: ''
      ,name:''
      ,email:''
      ,phone:''
      ,country:'US'
      ,verifycode:''
      ,isverifying:false
      ,phoneverified: false
      ,userattributes:{}
      ,smsmfaenabled:false
      ,smsmfaenabledorig:false
      ,totpmfaenabled:false
      ,totpmfaenabledorig:false
      ,rdomfapreference:'none'
      ,rdomfapreferenceorig:'none'
      ,totpsecret:''
      ,txtverifyotp:''
      ,authclass:{}
      ,showConfigureOtp:false
      ,isverifyingotp:false
      ,modalmsg:''
      ,ddlprimaryorg:''
    };
  
    this.spfxd = new spfxdata();
    
    this.openModal = this.openModal.bind(this);
    this.closeModal = this.closeModal.bind(this);

    this.oncomponentmounted = (susr)=> {


        this.spfxd.setuser(susr);
        
        var that = this;
        
        
         //Auth.currentAuthenticatedUser().then(user=>{
            Auth.currentUserPoolUser({bypassCache:true}).then(user=>{

            Auth.currentUserInfo().then(info=>{
            var tmp = {};
                    tmp.name = info.attributes.name;
                    tmp.email = info.attributes.email;
                    tmp.phone = info.attributes.phone_number;
                    tmp.phoneverified = (info.attributes.phone_number_verified);
                    tmp.userattributes = info.attributes;
                    that.setState(tmp);
                    
                    
            }).catch(function(e){
                that.setState({msg:'Failed to get the current session' + JSON.stringify(e)})
            });

            Auth.getPreferredMFA(user).then(mfa=>{
            	
                switch(mfa){
                    case "SMS_MFA":
                        that.setState({rdomfapreference:'sms',rdomfapreferenceorig:'sms'});
                    break;
                    case "SOFTWARE_TOKEN_MFA":
                        that.setState({rdomfapreference:'totp',rdomfapreferenceorig:'totp'});
                    break;
                    default:
                        //NOFMA
                         that.setState({rdomfapreference:'none',rdomfapreferenceorig:'none'});
                    
                    break;
                }
                
                
                
                
                
                
            
             
            
            }).catch(function(e){
                that.setState({msg:'Failed to get MFA preference' + e.message})
            });

            
         }).catch(function(e){
             that.setState({msg:'Failed to get currentAuthenticatedUser' + e.message})
         });
            
            
	        this.spfxd.getuserorglist().then((orgs)=>{
	        	that.setState({ orglist : orgs });
	        }).catch((e)=>{
	        	that.setState({msg:'Failed to get orgs for user.' + e.message})
	        });
	            
         
         

        /*Auth.currentUserPoolUser({bypassCache:false}).then(user=>{
                Auth.userSession(user).then(function (session) {
                     that.setState({msg:JSON.stringify(session.idToken.payload),isLoading:false});
                     
                        var tmp = {};
                        tmp.name = session.idToken.payload.name;
                        tmp.email = session.idToken.payload.email;
                        tmp.phone = (session.idToken.payload.phone_number)?session.idToken.payload.phone_number:'';
                        //tmp.msg = JSON.stringify(susr.idtoken.payload);
                        //tmp.msg = JSON.stringify(susr)
                        //that.setState(tmp);

       
                }).catch(function (e) {
                    that.setState({msg:'Failed to get the current session' + JSON.stringify(e)})
                    
                });
        });*/

         //let user = await Auth.currentAuthenticatedUser();
        
        
        //this.setState({});

    };


}

handleChange = event => {
   
    if(event.target.value=='true'||event.target.value=='false'){
        this.setState({
         [event.target.id]: (event.target.value==true || event.target.value=='true')
        });
    }else{
        this.setState({
         [event.target.id]: event.target.value
        });
    }
  }


enableSMSAuth = async (boolin) => {
    
	return new Promise(async (resolve,reject)=>{
		
		try{
			
			 Auth.currentUserPoolUser({bypassCache:true}).then(async (user)=>{
				 
			var res;
            if(boolin){
                res = await Auth.enableSMS(user);
                res = await Auth.setPreferredMFA(user, 'SMS');
                resolve(res);
            }else{
                res = Auth.disableSMS(user);
                reject(res);
            }			
            
			 });
	        
	    }catch(mfaerr){
	       //error
	    	reject(mfaerr);	       
	    }
	    
	});
	
    
}

enableTOTPAuth = async (user, boolin) =>
{
	 try{
         if(boolin){
             //alert('do enable')
             Auth.setupTOTP(user).then((data)=>{
            	 this.setState({msg:JSON.stringify(data)});
             }).catch((err)=>{
            	 this.setState({msg:JSON.stringify(err)});
             });
             
         }else{
             //alert('do disable')
             //await Auth.disableSMS(user);
         }
     
 }catch(mfaerr){
    //error
    //alert(JSON.stringify(mfaerr));
	 this.setState({msg:JSON.stringify(mfaerr)});
 }
}

updateProfile = async event => {
    event.preventDefault();

    
    //use user.accessToken.jwtToken to enable TOTP via soft device;
    // aws cognito-idp associate-software-token --access-token
    // aws cognito-idp verify-software-token --friendly-device-name spfXio --user-code /d{6} --access-token
    // aws cognito-idp set-user-mfa-preference --software-token-mfa-settings Enabled=true,PreferredMfa=true --access-token
    //tstate.msg = JSON.stringify(susr.accessToken,10);
    this.setState({isLoading:true})
    try{
        let user = await Auth.currentAuthenticatedUser();

        //check to see if anything has changed before updating
        //alert(this.state.smsmfaenabled);
       
        var thismsg = '';
        
        //do updates if user has more than one organization, and the organization selection has changed
        if(this.state.orglist.length>1){
        	var selOrgId = this.getOrgValue(this.state.orglist).value
        	if(this.hasOrgValueChanged(this.state.orglist,selOrgId)){
        		var params = {};
        		params.spfx={};
        		params.spfx.primaryorgid = selOrgId;
        		try{
        			var res = await this.spfxd.updateuserprimaryorg(params);
        			
        			if(res.recordset.affectedRows==1){
        				thismsg += 'updated primary org for user';
        				
        				var uporg = this.state.orglist;
        				for(var o of uporg){
        					o.primaryorgid=selOrgId;
        				}
        				
        				this.setState({ orglist:uporg } );
        				
        			}else{
        				thismsg += 'failed updating primary org for user';
        			}
        		}catch(err){
        			thismsg += 'error updating user primary org;';
        		}
        	}
        }
        
        let result = await Auth.updateUserAttributes(user,{
            'name': this.state.name
            ,'phone_number':this.state.phone
            
        });


        thismsg += 'profile updated';
        var phoneverified = (this.state.phone==this.state.userattributes.phone_number)
        //if(!phoneverified){
          //  this.enableSMSAuth(user,false);
        //}
        //alert(`is phone verified ${phoneverified}`)
        var that=this;
        this.setState({
                      phoneverified: phoneverified
                    , msg:thismsg
                    , isLoading:false
                    , smsmfaenabledorig:that.state.smsmfaenabled
                    });
        
    }catch(err){
        this.setState({msg:JSON.stringify(err),isLoading:false});
    }
}

verifyPhone = async event =>{
    event.preventDefault();

    this.setState({isLoading:true})
    try{
        //let user = await Auth.currentAuthenticatedUser();
        var isverifying = this.state.isverifying
        var thismsg = ''
        var phoneverified = this.state.phoneverified;
        if(!this.state.isverifying){
        let result = await Auth.verifyCurrentUserAttribute('phone_number');
        isverifying=true;
        thismsg = 'verification code sent';
        }else{
                //verifyCurrentUserAttributeSubmit
                let result = await Auth.verifyCurrentUserAttributeSubmit('phone_number',this.state.verifycode);
            isverifying=false;
            thismsg = 'phone number verified';
            phoneverified = true;
        }

        this.setState({isLoading:false,msg:thismsg,isverifying:isverifying,phoneverified:phoneverified});

    }catch(err){
        this.setState({msg:JSON.stringify(err),isLoading:false});
    }
}
//-----------------------------------------------------------------------------------------------------------------------------
handleVerifyOTP = async (event) =>{
	 event.preventDefault();
	 
	 this.setActiveState({isverifyingotp:true});
	 var tstate = {};    
	 
	 	try{
	 
	 	let res = await this.verifyotp(this.state.txtverifyotp);
	    	 tstate.msg = "TOTP Enabled"
		     tstate.showConfigureOtp = false;
	 		 tstate.rdomfapreferenceorig = 'totp';
		}catch(err) {
		    // Token is not verified
			tstate.modalmsg = err.message;
			tstate.showConfigureOtp = true;
		};
	 
	 
    tstate.isverifyingotp = false;
    this.setActiveState( tstate );
}

//-----------------------------------------------------------------------------------------------------------------------------
handleTxtFieldChange = event => {
    this.setState({
      [event.target.id]: event.target.value
    });
  }
//-----------------------------------------------------------------------------------------------------------------------------
handleRdoChange = async event => {
      
        this.setState({
            [event.target.name]: event.target.value
            });
                
       
        switch(event.target.name){
        
        case "rdomfapreference":
        		switch(event.target.value){
        			case 'totp':
        				
        				var code = await this.setupotp(this.susr).catch((err)=>{alert(err)});
        				if(code){
        					this.setState({totpsecret:code,showConfigureOtp:true});
        				}
                    break;
                    
        			case 'sms':
        				if(!this.state.phoneverified){
        					this.setState( {showverifycell:true});
        				}else{
        					  
        					 try{
        					   this.enableSMSAuth(true).then((res)=>{
        				    	if(res=='SUCCESS'){
        				    		this.setState({msg:'SMS MFA Enabled'});
        				    	}  else{
        				    		this.setState({msg:res});
        				    	}
        				    	
        				    	this.setState({rdomfapreferenceorig:this.state.rdomfapreference});
        				      }).catch(smserr=>{
        				    	  this.setState({msg:smserr.message})
        				      });
        				      
        					 }catch(err){
        						 this.setState({msg:err.message});
        					 }
        				}
        				 
        			break;
        			case 'none':
        					let user = await Auth.currentAuthenticatedUser();
        					Auth.setPreferredMFA(user, 'NOMFA');
        					this.setState({rdomfapreferenceorig:this.state.rdomfapreference});
        				break;
        		}

        	break;
        
        }
    
}

async verifyotp(code){
	return new Promise((resolve,reject)=>{
		
		 Auth.currentUserPoolUser({bypassCache:true}).then(user=>{

			 Auth.verifyTotpToken(user,code).then(() => {
			    	// don't forget to set TOTP as the preferred MFA method
				    Auth.setPreferredMFA(user, 'TOTP').then((data)=>{
				    	resolve(data);
				    }).catch(pe =>{
				    	reject(pe);
				    });
				    
				}).catch( e => {
				    // Token is not verified
					reject(e)
				});
			 
		 }).catch((err)=>{
			 reject(err);
		 })
		 
		 
		 
	});
}
async setupotp(user){
	return new Promise((resolve,reject)=>{
	 Auth.currentUserPoolUser({bypassCache:true}).then(user=>{
		 
		 Auth.setupTOTP(user).then((code)=>{
				resolve(code);
			}).catch((err)=>{
				reject(err)
			});
		 
	 }).catch((err)=>{
		 reject(err);
	 })
	});

		
}

validateForm(){
    return (
        (this.state.name.length > 0 
            && this.state.phone > 0)
    )
}

openModal(modaltype){
	 
 	switch(modaltype){
 	case 'configureotp':
 		
 		/*
 		this.setActiveState({     showConfigure:true
 						  , txtsendingdomain:d.senderdomain
 						  , includedomains:d.includes
 						  , rdoaddpolicy:d.defaultSpfPolicy
 						  , rdoaddaudit:d.defaultSourceAuditing
 						  , rdoaddemailcapture:d.enableEmailCapture
 						  , isinsert:false
 						  , hosteddomainid:d.hosteddomainid
 					  });*/
 	break;
 	
 	
    
 	}
}
//-----------------------------------------------------------------------------------------------------------------------------
closeModal(){
       this.setActiveState({showConfigureOtp:false,rdomfapreference:this.state.rdomfapreferenceorig});
}
 
getOrgOptions(orgs){
	var options = [];
	try{
		if(orgs){
			
			for(let org of orgs){
				  
				options.push({ value: org.orgid, label :org.friendlyName })
				
			}
		}
	}catch(err){
		//this.setActiveState({msg:err.message});
	}
	return options;
	
}
hasOrgValueChanged(orgs,selval){
	var retval = false;
	if(orgs){
		for(let org of orgs){
			if(org.orgid==this.state.ddlprimaryorg && org.orgid!= org.primaryorgid){
				retval=true;
			}
		}
		
	}
	return retval;
}
getOrgValue(orgs){
	var value = {};
	try{
	if(orgs){
		if(this.state.ddlprimaryorg==''){
			for(let org of orgs){
				if(org.orgid==org.primaryorgid || this.state.ddlprimaryorg == org.orgid){
					value = { value: org.orgid, label :org.friendlyName }
				}
			}
		}else{
			for(let org of orgs){
				if(this.state.ddlprimaryorg == org.orgid){
					value = { value: org.orgid, label :org.friendlyName }
				}
			}
		}
		
	}
	}catch(err){
		//this.setActiveState({msg:err.message});
		
	}
	return value;
	
}


handleSelectChange = (event,id) =>{
	
	var st = {};
	st[id]=event.value;
	this.setState(st);
}  


render() {
        
    return (
			<Fragment>
				<TopNav msg={ this.state.msg }/>
     			<LeftNav />
				<RightNav />
                <div className="page-wrapper">
					<div className="container-fluid">
						<div class="row">
                             <div className="col-lg-12 col-sm-12 domainbox">
                                <div className="panel panel-default card-view profilecontainer">

													<div class="col-lg-12">
														<div class="">
															<div class="panel-wrapper collapse in">
																<div class="panel-body pa-0">
																	<div class="col-sm-12 col-xs-12">
																		<div class="form-wrap">
																			<form onSubmit={this.updateProfile}>
																				<div class="form-body overflow-hide">
																					<div class="form-group">
                                                                                        <label class="control-label mb-10" for="exampleInputEmail_01">Full Name</label>
																						<div class="input-group">
                                                                                            <div class="input-group-addon"><i class="icon-user"></i></div>
                                                                                            <input type="name" class="form-control" id="name" placeholder="Full Name" value={this.state.name} onChange={this.handleChange}  />
                                                                                        </div>
                                                                                    </div>
																					<div class="form-group">
																						<label class="control-label mb-10" for="exampleInputEmail_01">Email address</label>
																						<div class="input-group">
																							<div class="input-group-addon"><i class="icon-envelope-open"></i></div>
																							<input type="email" class="form-control" id="exampleInputEmail_01" placeholder="youremail@yourdomain.com" value={this.state.email} readonly="true" />
																						</div>
																					</div>
																					<div class="form-group">
																						<label class="control-label mb-10" for="exampleInputContact_01">Cell number</label>
																						<div class="input-group">
																							<div class="input-group-addon xx-small-container"><LoaderButton
                                                                                                block
                                                                                                bsSize="small"
                                                                                                type="button"
                                                                                                onClick={this.verifyPhone}
                                                                                                loadingText="update in progress"
                                                                                                className={(this.state.phoneverified)?"hidden":"btn btn-danger btn-icon-anim btn-square xx-small icon-check"}
                                                                                                style={{marginTop:'0px',paddingTop:'0px'}}
                                                                                                tabIndex="3"
                                                                                                pIcon={<i className="icon-check"></i>}
                                                                                                / >  </div>
																							<input type="phone" id="phone" class="form-control phone" placeholder="+102 9388333"  onChange={this.handleChange} value={this.state.phone}   />
																						</div>
																					</div>
                                                                                    <FormGroup controlId="verifynumber" bsSize="large" className={(this.state.phoneverified)?"hidden":"form-group"} >
                                                                                        <ControlLabel className={(this.state.isverifying)?"control-label mb-10 font-16":"hidden"}>Verification Code</ControlLabel>
                                                                                        <FormControl
                                                                                            autoFocus
                                                                                            type="string"
                                                                                            onChange={this.handleChange}
                                                                                            value={this.state.verifycode}
                                                                                            id="verifycode"
                                                                                            className={(this.state.isverifying)?"form-control txt-primary block mb-10 pull-right font-16":"hidden"}
                                                                                        />
                                                                                        
                                                                                    </FormGroup>
                                                                                   <div class="form-group">
																						<label class="control-label mb-10">Country</label>
																						<select class="form-control" data-placeholder="Choose a Category" tabindex="1">
																							<option value="USA" selected={(this.state.country==='USA')}>USA</option>
																							<option value="UK" selected={(this.state.country==='UK')}>United Kingdom</option>
																							<option value="FR" selected={(this.state.country==='FR')}>France</option>
																						</select>
																					</div>
																					<div class={(this.state.orglist)?(this.state.orglist.length>1)?'form-group':'hide':'hide'}>
																						<label class="control-label mb-10">Primary Organization</label>
																						<Select id="ddlprimaryorg" onChange={(event)=>{ this.handleSelectChange(event,'ddlprimaryorg') } } options={ this.getOrgOptions(this.state.orglist) } value={ this.getOrgValue(this.state.orglist) } class="form-control" data-placeholder="Select an organization" />	
																					</div>
																						
																					<div class="form-group">
																						<label class="control-label mb-10">Multi-Factor Authentication Preferences</label>
																							<div class="radio-list">
		                                                                                        <div class="radio-inline pl-20">
		                                                                                            <span class="radio radio-info">
		                                                                                                <input type="radio" name="rdomfapreference" id="rdomfapreferencetotp" value="totp"  onChange={this.handleRdoChange} checked={this.state.rdomfapreference=='totp'} defaultChecked={this.state.rdomfapreference=='totp'} />
		                                                                                            <label htmlFor="rdodefaultspfauthpolicyneutral">TOTP</label>
		                                                                                            </span>
		                                                                                            <span class="radio radio-info">
		                                                                                            <input type="radio" name="rdomfapreference" id="rdomfapreferencesms" value="sms"  onChange={this.handleRdoChange} checked={this.state.rdomfapreference=='sms'} defaultChecked={this.state.rdomfapreference=='sms'}  />
		                	                                                                        <label htmlFor="rdodefaultspfauthpolicysoftfail">SMS</label>
		                	                                                                        </span>
		                	                                                                        <span class="radio radio-info">
		                	                                                                            <input type="radio" name="rdomfapreference" id="rdomfapreferencenone" value="none"  onChange={this.handleRdoChange}  checked={this.state.rdomfapreference=='none'} defaultChecked={this.state.rdomfapreference=='none'}  />
		                	                                                                        <label htmlFor="rdodefaultspfauthpolicyfail">none</label>
		                	                                                                        </span>
		                                                                                        </div>
		                                                                                    </div>	
				                                                                   </div>
				                                                                   
				                                                                   
				                                                                     
																				</div>
																				<div class="form-actions mt-10">			
																					<LoaderButton
                                                                                        block
                                                                                        bsSize="large"
                                                                                        disabled={!this.validateForm()}
                                                                                        type="submit"
                                                                                        isLoading={this.state.isLoading}
                                                                                        text="Update Profile"
                                                                                        loadingText="update in progress"
                                                                                        className="btn btn-info btn-primary btn-rounded updateprofile"
                                                                                        tabIndex="3"
                                                                                        />  
                                                                                       
                                                                                       
																				</div>				
																			</form>
																		</div>
																	</div>
																</div>
															</div>
														</div>
													</div>
												</div>
                                            </div>
                                        </div>

					
                                        <Modal id="responsive-modal"
                                    		dialogClassName="configureotp"
                                            className="modal fade"
                                            show={this.state.showConfigureOtp}
                                            onHide={this.closeModal}
                                            animation={true}
                                            bsSize="medium">
                                            <Modal.Header closeButton className="modal-header">
                                                <Modal.Title className="modal-title">Configure Timebased One-Time Password Authentication</Modal.Title>
                                            </Modal.Header>
                                            <Modal.Body  className="modal-body">
                                                    <div id="modalmsg">
                                                    	Scan the QR code or enter the Secret Code to configure your soft-token application.
                                                    	<br /><br />
                                                    </div>
                                                    <form>
                                                        <div className="form-group">
                                                            <label for="recipient-name" className="control-label mb-10">QR Code:</label>
                                                            <div className="qrcode"><QRCode value={this.state.totpsecret} size="256" renderAs="canvas" level="H" includeMargin="true" /></div>
                                                        </div>
                                                        <div className="form-group">
        	                                                <label for="message-text" className="control-label mb-10">Secret Code:</label>
                                                             <div>{this.state.totpsecret}</div>
                                                        </div>
                                                        <div className="form-group">
	    	                                                <label for="message-text" className="control-label mb-10">Enter generated OTP below and click Verify</label>
	    	                                                <input type="text" className="form-control" id="txtverifyotp" placeholder="123456" onChange={this.handleTxtFieldChange} value={this.state.txtverifyotp}  />
	                                                    </div>
	    	                                            <div className="form-group">    
	    	                                                <label for="message-text" className="control-label mb-10">{this.state.modalmsg}</label>
	    	                                            </div>
                                                     
                                                    </form>
                                            </Modal.Body>

                                            <Modal.Footer>
                                                            <LoaderButton
                                                                    block
                                                                    type="button"
                                                                    text="Verify"
                                                                    loadingText="verifying..."
                                                                    isLoading={this.state.isverifyingotp}
                                                                    className="btn btn-primary"
                                                                    onClick={(e) => this.handleVerifyOTP(e)} 
                                                                    
                                                                />
                                                             <LoaderButton
                                                                    block
                                                                    type="button"
                                                                    text="Cancel"
                                                                    data-dismiss="modal"
                                                                    className="btn btn-default"
                                                                    onClick={this.closeModal}
                                                                />
                                            </Modal.Footer>

                                        </Modal>

                                        <SweetAlert
                        		        show={this.state.showverifycell}
                        		        title="Cell Number Verification"
                        		        text="You must verify your cell number before enabling SMS authentication."
                        		        type="warning"
                        		        
                        		        confirmButtonColor="#f0c541"
                        		        confirmButtonText = "Ok"
                        		        closeOnConfirm="false"
                        		        onConfirm={() => this.setState({ showverifycell: false,rdomfapreference:this.state.rdomfapreferenceorig })}
                        		        />
                                        
						<Footer />
					</div>
			</div>
            </Fragment>
		
    );
  }
  
  
}