import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import { BrowserRouter as Router } from "react-router-dom";
import Amplify from "aws-amplify";
import config from "./config";
import CacheStorage from "./classes/CacheStorage.js";


Amplify.configure({
    Auth: {
    mandatorySignIn: true,
    region: config.cognito.REGION,
    userPoolId: config.cognito.USER_POOL_ID,
    identityPoolId: config.cognito.IDENTITY_POOL_ID,
    userPoolWebClientId: config.cognito.APP_CLIENT_ID
    }
  , API: {
        endpoints: [
        	{
                name: "addauthorizedsender",
                endpoint: config.apiGateway.URL
            },
            {
            	name: "addhosteddomain",
            	endpoint: config.apiGateway.URL
            },
            {
            	name: "addhosteddomainpolicy",
            	endpoint: config.apiGateway.URL
            },
            {
            	name: "adddmarcpolicy",
            	endpoint: config.apiGateway.URL
            },
            {
                name: "createquicksightuser",
                endpoint: config.apiGateway.URL
            },
            {
                name: "getobj",
                endpoint: config.apiGateway.URL
            },
            {
                name: "getsigninurl",
                endpoint: config.apiGateway.URL
            },
            {
                name: "getembeddeddashboard",
                endpoint: config.apiGateway.URL
            },
            {
                name: "gethosteddomaindns",
                endpoint: config.apiGateway.URL
            },
            {
                name: "gethosteddomaindnsns",
                endpoint: config.apiGateway.URL
            },
            {
                name: "getspfrecord",
                endpoint: config.apiGateway.URL
            },
            {
                name: "getuserdomains",
                endpoint: config.apiGateway.URL
            },
            {
                name: "getuserorglist",
                endpoint: config.apiGateway.URL
            },
            {
                name: "updateuserprimaryorg",
                endpoint: config.apiGateway.URL
            },
            {
                name: "getuserdomainsenders",
                endpoint: config.apiGateway.URL
            },
             {
                name: "getuserprivs",
                endpoint: config.apiGateway.URL
            },
            {
                name: "getuserprivs",
                endpoint: config.apiGateway.URL
            },
            {
            	name: "deleteauthorizedsender",
            	endpoint: config.apiGateway.URL
            },
            {
            	name: "getaudittrails",
            	endpoint: config.apiGateway.URL
            },
            {
            	name: "adddomainkey",
            	endpoint: config.apiGateway.URL
            },
            {
            	name: "upsertdkimkeys",
            	endpoint: config.apiGateway.URL
            }
        ]
    }
    , authenticationFlowType: 'USER_PASSWORD_AUTH'
    , storage : new CacheStorage({defaultTTL:(60*60)*1000,itemMaxSize:3000,storage:sessionStorage})
    
});


   
   
ReactDOM.render(

  
  <Router>
    <App />
  </Router>
  ,document.getElementById("root")
  
  
  
);


// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
