import { Auth } from "aws-amplify";
import React, { Component, Fragment } from "react";
import { LinkContainer } from "react-router-bootstrap";
import { Nav, Navbar, NavItem } from "react-bootstrap";

import "./NavBar.css";

export default class NavBar extends Component {
	constructor(props) {
		super(props);

		this.state = {
		  isLoading: true,
		  notes: []
		};
	}
  
    handleLogout = async event => {
	  await Auth.signOut();
	  this.setState({ userHasAuthenticated:false });
	  this.props.history.push("/signin");
	}
  
	async componentDidMount() {
	  try {
		await Auth.currentSession();
		this.setState({ userHasAuthenticated: true });
	  }
	  catch(e) {
		if (e !== 'No current user') {
		  alert(e);
		}
	  }

	  this.setState({ isAuthenticating: false });
	}
	


	render(){
		return (<Fragment />);
	}
  
	render1() {
    return (
      <Fragment>
	  <Navbar fluid collapseOnSelect>
			<Navbar.Collapse>
			{/* Toggle menu options according to user authentication status */}
					{this.state.userHasAuthenticated
					 ?<Nav pullRight>
						{/* Menu Options for Authenticated Users */}
						  <LinkContainer to="/signout">
							<NavItem>Sign Out</NavItem>
						  </LinkContainer>
						  <LinkContainer to="/getobj">
							<NavItem>Fetch Object</NavItem>
						  </LinkContainer>
						  <LinkContainer to="/quicksight">
							<NavItem>Reports</NavItem>
						  </LinkContainer>
						</Nav>
					  : <Fragment>
						<Nav pullLeft>
						{/* Public Menu Options */}
						  <LinkContainer to="/index">
							<NavItem>Home</NavItem>
						  </LinkContainer>
						  <LinkContainer to="/about">
							<NavItem>About</NavItem>
						  </LinkContainer>
						  <LinkContainer to="/contact">
							<NavItem>Contact</NavItem>
						  </LinkContainer>
						</Nav>
						<Nav pullRight>
						  <LinkContainer to="/signup">
							<NavItem>Sign Up</NavItem>
						  </LinkContainer>
						  <LinkContainer to="/signin">
							<NavItem>Sign In</NavItem>
						  </LinkContainer>
						 
						</Nav>
						</Fragment>
					  }
				
			</Navbar.Collapse>
		  </Navbar>
		 </Fragment>
    );
  }
  
  
}


