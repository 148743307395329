import { Auth, API } from "aws-amplify";

/** @class */
export default class spfxioapi {

  /**
   * Constructs a new spfxioapi object
   * @param {object} config spfxdata config options 
   */
  constructor(susr) {
   this.susr = susr;

  }

   async addauthorizedsender(params)
   {
	   params.jwtToken = this.susr.idToken.jwtToken;
	   var body = {};
	   body.jwtToken = params.jwtToken;
	   body.spfx = params.spfx;
	  
	   return API.put('addauthorizedsender', '/addauthorizedsender', { body: params, headers:{}});
	   
   }
   
   async adddomainkey(params)
   {
	  
	   return new Promise((resolve,reject)=>{
		   try{
		   params.jwtToken = this.susr.idToken.jwtToken;
		   var body = {};
		   body.jwtToken = params.jwtToken;
		   body.spfx = params.spfx;
			  
		   	var res = API.put('adddomainkey', '/adddomainkey', { body: params, headers:{}}) ;
		   	resolve(res);
		   }catch(err){
			 reject(err);
		   }   
		   
	   });
		  
	    
	   
   }
   
   async upsertdkimkeys(params)
   {
	  
	   return new Promise((resolve,reject)=>{
		   try{
		   params.jwtToken = this.susr.idToken.jwtToken;
		   var body = {};
		   body.jwtToken = params.jwtToken;
		   body.spfx = params.spfx;
			  
		   	var res = API.put('upsertdkimkeys', '/upsertdkimkeys', { body: params, headers:{}}) ;
		   	resolve(res);
		   }catch(err){
			 reject(err);
		   }   
		   
	   });
		  
	    
	   
   }
   
   async addhosteddomainpolicy(params)
   {
	   params.jwtToken = this.susr.idToken.jwtToken;
	   var body = {};
	   body.jwtToken = params.jwtToken;
	   body.spfx = params.spfx;
	  
	   return API.put('addhosteddomainpolicy', '/addhosteddomainpolicy', { body: params, headers:{}});
	   
   }
   
   async adddmarcpolicy(params)
   {
	   params.jwtToken = this.susr.idToken.jwtToken;
	   var body = {};
	   body.jwtToken = params.jwtToken;
	   body.spfx = params.spfx;
	  
	   return API.put('adddmarcpolicy', '/adddmarcpolicy', { body: params, headers:{}});
	   
   }
   
   async addhosteddomain(params){
	   params.jwtToken = this.susr.idToken.jwtToken;
	   return API.put('addhosteddomain', '/addhosteddomain', { body: params, headers:{}});
   }
   
   
   
   async getuserdomains(){
	   return API.put('getuserdomains', '/getuserdomains', { body:{ "jwtToken" : this.susr.idToken.jwtToken},headers:{}});
   }
   
   async getuserorglist(){
	   
	   return new Promise((resolve,reject)=>{
		   try{
			var body = {};
			body.jwtToken = this.susr.idToken.jwtToken;
			var res = API.put('getuserorglist', '/getuserorglist', { body:body,headers:{}});
		    
		   	 resolve(res);
		   }catch(err){
			 reject(err);
		   }   
		   
	   });
	   
   }
   
   async updateuserprimaryorg(params){
	   
	   return new Promise((resolve,reject)=>{
		   try{
			   
			   var body = {};
				body.jwtToken = this.susr.idToken.jwtToken;
				body.spfx = params.spfx;
				var res = API.put('updateuserprimaryorg', '/updateuserprimaryorg', { body:body,headers:{}});
			    
			   	 resolve(res);
		   }catch(err){
			 reject(err);
		   }   
	   })
   }
   
   
   async getuserdomainsenders(){
	   return API.put('getuserdomainsenders', '/getuserdomainsenders', { body:{ "jwtToken" : this.susr.idToken.jwtToken},headers:{}});
   }
 
   async gethosteddomaindns(spfxio_id){
	   return API.put('gethosteddomaindns', '/gethosteddomaindns', { body:{ "jwtToken" : this.susr.idToken.jwtToken, "spfxio_id" : spfxio_id},headers:{}});
   }
   
   async gethosteddomaindnsns(spfxio_id,hostname){
	   return API.put('gethosteddomaindnsns', '/gethosteddomaindnsns', { body:{ "jwtToken" : this.susr.idToken.jwtToken, "spfxio_id" : spfxio_id, "hostname" : hostname},headers:{}});
   }
   
   

   async getaudittrails(spfxio_id){
	   return API.put('getaudittrails', '/getaudittrails', { body:{ "jwtToken" : this.susr.idToken.jwtToken, "spfxio_id" : spfxio_id},headers:{}});
   }
   
  
   
   async deleteauthorizedsender(params)
   {
	   params.jwtToken = this.susr.idToken.jwtToken;
	   var body = {};
	   body.jwtToken = params.jwtToken;
	   body.spfx = params.spfx;
	  
	   return API.put('deleteauthorizedsender', '/deleteauthorizedsender', { body: params, headers:{}});
   }
   
   
   

}