import React, { Fragment } from "react";
import { Auth, API } from "aws-amplify";
import LoaderButton from "../components/LoaderButton";
import { FormGroup, FormControl, ListGroup, ListGroupItem } from "react-bootstrap";

import ReactTable from "react-table";
import LeftNav from "../components/LeftNav"
import TopNav from "../components/TopNav"
import RightNav from "../components/RightNav"
import Footer from "../components/Footer"
import BaseComponent from "../classes/BaseComponent"

import '../css/react-table.css';
import "./spfinspector.css";

export default class spfinspector extends BaseComponent {
  constructor(props) {
    super(props);
    
    
    this.state = {
        isLoading: false
      , redirecturl: ""
      , msg:""
      , spfresults:[]
      , dnsname:""
   };
    
    this.removeresultitem = this.removeresultitem.bind(this);
    
    this.oncomponentmounted = (susr)=> {
        //this.setActiveState({"msg":"page loaded"});
        //this.prepareredirect(susr);
    
    };
	
	
  }

    
    async prepareredirect(susr){
        try{
        var retval = await this.geturl(susr.idToken.jwtToken,'https://us-east-1.quicksight.aws.amazon.com/sn/start');
            this.setActiveState({"redirecturl":retval});
            //var win = window.open(retval, '_blank');
            //win.focus();
        }catch(e){
            this.setActiveState("error encountered during redirect.  aborting.");
        }
    }
 
  
    geturl(jwtToken, redirecturl){
        
        return API.put('getsigninurl', '/getsigninurl', { body:{ "jwtToken" : jwtToken, "redirect_url":redirecturl},headers:{}});
    }
    
  renderErrors(errors){
    try{
    return [{}].concat(errors).map(
        (error,i)=>
            i!==0
            ?<div className="spferror"><label>Error</label>{ JSON.stringify(error) }</div>
            :<Fragment />
           
    )
    }catch(err){
        return (<Fragment/>)
    }

  }
   
  renderSpfRecords(spfrecords){
    return [{}].concat(spfrecords).map(
        (spfrecord,i)=>
            i!==0
            ?<li><label>{spfrecord.hostname}</label>{ spfrecord.spfrecord }</li>
            :<Fragment />
           
    )
  }
  
  renderSpfResults(spfresults){
	  var spfcolumns =  
          [
            {	    Header:'Num'
          	  , Cell: (row)=> { return <span>{row.index+1}</span> }
          	  , minWidth:50
                , maxWidth:50
            },{
                Header:'Parent'
                    , accessor: 'parentrecord'
                    , minWidth:100
                    , maxWidth:250
            },{
                Header:'Host'
                , accessor: 'hostname'
                , minWidth:100
                , maxWidth:250
            }  
            , {
                 Header:'Value'
                ,accessor: 'record'
                ,style: {whiteSpace:'unset'}
            }
          ];
  
	  var spfhostname='notset';
	  let confighints = [];
	  if(spfresults[0]){
		if(spfresults[0].spf){  
			
			var numspfrecords = spfresults[0].spf.filter((r)=>{ return (r.parentrecord===r.hostname)}).length;
			if(numspfrecords>1){
				confighints.push({
					  message : 'Only 1 SPF record is permitted per domain.  Your domain currently has ' + numspfrecords + ' configured.  This results in permanent errors during SPF authentications.  Permanent errors significantly increase risks that fraudulent or spoofed emails may be accepted by receiving email gateways.'
					  , type: 'SPF'					
				})
			}
			
			var defaultspf = (spfresults[0].spf.filter((r)=>{ return (r.parentrecord===r.hostname)}).length===1)?spfresults[0].spf.filter((r)=>{ return (r.parentrecord===r.hostname)})[0].defaultaction:'-';
			switch(defaultspf){
				case "fail":
				
				break;
				case "softfail":
					confighints.push({
						  message : 'Your SPF default authentication response may be further strengthened by changing from [softfail] to [fail].'
						  , type: 'SPF'					
					})
				break;
				case "neutral":
					confighints.push({
						  message : 'Your domain\'s SPF record is configured to return a [neutral] authentication result by default.  The [neutral] default response increases risk that fraudulent or spoofed emails may be accepted by receiving email gateways.  Your SPF default authentication response may be further strengthened by changing from [neutral] to [softfail] or [fail].'
						  , type: 'SPF'					
					})
				break;
				case "pass":
					confighints.push({
						  message : 'Your domain\'s SPF record is configured to return a [pass] authentication result by default.  The [pass] default response authorizes every IP address to send email on behalf of your domain.  This significantly increases risk that fraudulent or spoofed emails may be accepted by receiving email gateways.  Your SPF default authentication response may be further strengthened by changing from [pass] to [neutral] or [softfail] or [fail].'
						  , type: 'SPF'					
					})
				break;
				default:
					confighints.push({
						  message : 'Your domain\'s SPF record was not identified or may not be properly configured.  Verify your SPF record.'
						  , type: 'SPF'					
					})
				break;
			
			}	
			
			var spflookupcount = spfresults[0].lookupcount;
			switch(true){
				case (spflookupcount>10):
					confighints.push({
						  message : 'SPF permits up to 10 name lookups to be completed during SPF authentication.  Your SPF configuration exceeds that limit with ' + spflookupcount + ' lookups.  Exceeding the lookup limit results in permanent errors during SPF authentication.  Exceeding the lookup limit significantly increases risk that fraudulent or spoofed emails may be accepted by receiving email gateways.'
						  , type: 'SPF'					
					})
				break;
				case (spflookupcount>=7):
					confighints.push({
						  message : 'SPF permits up to 10 name lookups to be completed during SPF authentication.  Your SPF configuration initiates ' + spflookupcount + ' name lookups.  Modifications to third-party referenced records may result in exceeding the lookup limit for your domain and cause permanent errors during SPF authentication.  Exceeding the lookup limit significantly increases risk that fraudulent or spoofed emails may be accepted by receiving email gateways.'
						  , type: 'SPF'					
					})
				break;
			}
			
			var spflookuperrors = spfresults[0].spflookuperrors;
			if(spflookuperrors>0)
			{
				confighints.push({
						  message : 'SPF lookup errors were identified.  This often occurs due to hostname configuration, SPF mechanism misuse, or as a result of macro expansion during testing of your record.'
						  , type: 'SPF'					
					})
			}
			
			var dmarcaction = spfresults[0].dmarc.action;
			switch(dmarcaction){
				case "reject":
				
				break;
				case "quarantine":
					confighints.push({
						  message : 'Your domain\'s DMARC configuration publishes a [quarantine] default action policy, specifying that emails failing DMARC authentication should be quarantined.  Your DMARC configuration may be further strengthened by moving from [quarantine] policy to a [reject] policy.'
						  , type: 'DMARC'					
					})
				break;
				case "none":
					confighints.push({
						  message : 'Your domain\'s DMARC configuration publishes a [none] policy.  Your DMARC configuration may be further strengthened by moving from [none] policy to a [quarantine] or [reject] policy.'
						  , type: 'DMARC'					
					})
				break;
				default:
						
				confighints.push({
						  message : 'A DMARC configuration was not identified for your domain.'
						  , type: 'DMARC'					
					})
				break;
			
			}
			
			var rua = spfresults[0].dmarc.rua;
			var ruf = spfresults[0].dmarc.ruf;
			var rxmailto = new RegExp(/^mailto:.+$/);
					
			if(!rxmailto.exec(rua)){
				confighints.push({
						  message : 'A valid DMARC RUA destination was not identified.'					
					})
				
			}
			if(!rxmailto.exec(ruf)){
				confighints.push({
						  message : 'A valid DMARC RUF destination was not identified.'					
					})
				
			}
		
		}  
	  }
  
	  
  return [{}].concat(spfresults).map(
    (spfresult, i) =>
      i !== 0
        ? 
           <ListGroupItem className="spfresult">
      		<div className="pull-right">
      		<ul>
      		<li>
				<a onClick={(e) => this.removeresultitem(e,spfresult.hostname)} ><i className="zmdi zmdi-close mr-20"></i></a>
			</li>
			</ul>
			</div>
			
             <h3>{spfresult.hostname}</h3>
             
             <div className="gradecontainer">
	             <div className={(spfresult.score.grade==='F')?'grade grade_f grade-selected':'grade grade_f'}>F</div>
	             <div className={(spfresult.score.grade==='D')?'grade grade_d grade-selected':'grade grade_d'}>D</div>
	             <div className={(spfresult.score.grade==='C')?'grade grade_c grade-selected':'grade grade_c'}>C</div>
	             <div className={(spfresult.score.grade==='B')?'grade grade_b grade-selected':'grade grade_b'}>B</div>
	             <div className={(spfresult.score.grade==='A')?'grade grade_a grade-selected':'grade grade_a'}>A</div>
	             <div className={(spfresult.score.grade==='A+')?'grade grade_aplus grade-selected':'grade grade_aplus'}>A+</div>
             </div>
             
             <div className="confighints">
             	{this.renderconfighints(confighints)}
             </div>
             
                
		     
	          
               
              <div class="panel-wrapper">
					<div class="panel panel-default">
					
						{this.renderspfrecordtree(spfresult.hostname,spfresult.spf,true)}
					
		    		</div>
		       </div>
		    
            
            
             
            </ListGroupItem>
          
        :   <Fragment />
          
  );
}

renderconfighints(hints){
	
 return [{}].concat(hints).map(
    (hint, i) =>
      i !== 0
        ? <div class="icon-ac-wrap pr-20"><div className="pull-left"><i className="zmdi zmdi-close-circle-o zmdi-hc-2x mr-20 confighinticon"></i></div><div className="confighintitem">{hint.message}</div></div>
        
        :''
        
        );


}

renderspfrecordtree(hostname, spfrecords, rootonly){
	  
	 var displayrecords;
	 
	 
	 
	 if(rootonly){
	 	
	 	displayrecords=spfrecords.filter((r)=>{ return (r.parentrecord===hostname && r.parentrecord===r.hostname)})
	 	
	 }else{
	  	 displayrecords=spfrecords.filter((r)=>{ return (r.parentrecord===hostname && r.parentrecord!==r.hostname)}) 
		 displayrecords.sort((a, b) => (a.hostname > b.hostname) ? 1 : -1)
		 var lsthost='';
		 var x=0;
		 if(displayrecords.length>1){
			 for(var h of displayrecords){
			 	if(h.hostname===lsthost){
			 		displayrecords = displayrecords.splice(displayrecords.indexOf(h),1)
			 	}
			 	lsthost=h.hostname
			 	//h.index = Math.floor((Math.random() * 10000) + 1);
			 	x++;
			 }
		 }
	 }
	 
	 
	 let gethostdisplay=function(record){
	 	let hostdisplay;
	 	if(spfrecords.filter((r)=>{ return (r.parentrecord===record.hostname)}).length>0){
	 		//haschildren
	 		//hostdisplay = <a role="button" data-toggle="collapse" data-parent={'#accordion_'+record.index}  href={'#collapse_'+record.index} aria-expanded="true"><div class="icon-ac-wrap pr-20"><span class="plus-ac"><i class="ti-plus"></i></span><span class="minus-ac"><i class="ti-minus"></i></span></div>{record.hostname}</a>
	 		hostdisplay = <a class="collapsed" role="button" data-toggle="collapse" data-parent={'#accordion_'+record.index} href={'#collapse_'+record.index} aria-expanded="true"><div class="icon-ac-wrap pr-20"><span class="plus-ac"><i class="ti-plus"></i></span><span class="minus-ac"><i class="ti-minus"></i></span></div>{record.hostname}</a>
	 	}else{
	 		//nochildren
	 		hostdisplay = <span style={{"margin-left":"30px"}}>{record.hostname}</span>
	 	}
	 	return hostdisplay;
	 }
	 
	 let getpadding=function(depth){
	 	return { "padding-left" : (depth*20)+'px',"border":"1px solid silver","margin":"10px"};
	 }
	 
	 
	  
	  return [{}].concat(displayrecords).map(
			    (record, i) =>
			      (i !== 0)
			        ? 
			        	<ListGroup className="">
			        	
			        			 <div  class="panel-group accordion-struct accordion-style-1" id={'accordion_'+record.index}>
					               <div class="panel panel-default">
										<div class="panel-heading" role="tab" id={'heading_'+record.index}>
											{ gethostdisplay(record) }
											<div style={{"margin-left":"55px"}}>
												{record.record}
												{this.renderErrors(record.errors)}
											</div>
										</div>
										
										<div  id={'collapse_'+record.index}  class="panel-collapse collapse" role="tabpanel">
											<div class="panel-body pa-15">
												<div className="col-md-12 mt-15">
														{ (record.parentrecord !== record.hostname || (rootonly===true))?this.renderspfrecordtree(record.hostname,spfrecords,false):''}
													
												</div>
											</div>
										</div>
									</div>
								</div>
						</ListGroup>
								
			      	:''
	)
 }
  
  

  removeresultitem = async (event,hostname) =>{
	  //alert(hostname);
	  var crrresults = this.state.spfresults;
	  var arrfilters = [];
	  for(let res of crrresults){
		  if(res.hostname!=hostname){
			  arrfilters.push(res);
		  }
	  }
	  this.setActiveState({spfresults:arrfilters});
	  
  }
  
 handleLookup = async event => {
    event.preventDefault();
	
    this.setActiveState({ isLoading: true });
	 try {
		 
		
        var spfresult = await this.fetch(this.state.dnsname);
		
        var cb = [spfresult].concat(this.state.spfresults);
       
        this.setActiveState( {spfresults: cb});
		
//		this.props.history.push("/");
	  } catch (e) {
		
		this.setActiveState({ "msg" : `Error fetching object ${this.state.objid}. ${e}`});
		
	  }
	  
	  this.setActiveState({ isLoading: false });
	  
  }
  
  fetch(dnsname){
	  
	  return API.put('getspfrecord', '/getspfrecord', { body:{ "dnsname" : dnsname},headers:{}});
	  
  }
 









  handleChange = event => {
    this.setActiveState({
      [event.target.id]: event.target.value
    });
  }


validateForm(){
    return (this.state.dnsname.length>0);
}
  
  render(){
       
		
	  return (
                <Fragment>
				<TopNav />
     			<LeftNav />
				<RightNav />
                {/*<!-- Main Content -->*/}
                <div class="page-wrapper">
					<div class="container-fluid">
						{/*<!--/ Title -->*/}
                        <div class="row"></div>
                        	<div class="col-sm-12 spfbox">
							<div class="panel panel-default card-view">
								<div class="panel-heading">
									<div class="pull-left">
										<h6 class="panel-title txt-dark">Domain Inspector</h6>
									</div>
									<div class="clearfix"></div>
								</div>
								<div class="panel-wrapper collapse in">
									<div class="panel-body">
										<div class="form-wrap">
                                            <div id="msg">
                                                Use the inspector to analyze SPF and DMARC records of sending domains.
                                                {this.state.msg}
                                            </div>
                                            <div class="lookupform">
                                                 <form onSubmit={this.handleLookup}>
	                                                <FormGroup controlId="objid">
	                                                    <FormControl
	                                                    bsSize="large"
	                                                    onChange={this.handleChange}
	                                                    id="dnsname"
	                                                    value={this.state.dnsname}
	                                                    className="lookupinput pull-left"
	                                                    />
	                                               
	                                                <LoaderButton
	                                                    block
	                                                    bsStyle="primary"
	                                                    bsSize="large"
	                                                    disabled={!this.validateForm()}
	                                                    type="submit"
	                                                    isLoading={this.state.isLoading}
	                                                    text="Inspect Domain"
	                                                    loadingText="Inspecting record . . . "
	                                                    className="pull-right lookupbtn"
	                                                />
	                                              </FormGroup>
	                                            </form>
                                            </div>
                                        </div>
                                       


                                    </div>
                                    
                                </div>
                            </div>
                            {/*visible={(this.state.spfresults.length>=1)}*/}
                            <div className={(this.state.spfresults.length>=1)? "spfresultscontainer":"spfresultscontainer-hidden"}>
                                <ListGroup className="">
                                
	                                {!this.state.isLoading && this.renderSpfResults(this.state.spfresults)}
	                                
		                         </ListGroup>
                            </div>
                        
                        </div>

                         <Footer />
					</div>
               
				{/*<!-- /Main Content -->*/}
		</div>
		</Fragment>
	  );
	  
  }
  
  
}