import { Auth } from "aws-amplify";
import React, { Component, Fragment } from "react";
import { FormGroup, FormControl, ListGroup, ListGroupItem, Modal, Radio} from "react-bootstrap";
import { withRouter } from "react-router-dom";
import Select from 'react-select';
import BaseComponent from "../classes/BaseComponent"

import SweetAlert from 'sweetalert-react';

import spfxdata from "../classes/spfxdata"

import LoaderButton from "../components/LoaderButton"

import "../css/style.css";


export default class Domain_DKIM extends BaseComponent {
	constructor(props) {
		super(props);

			   
		this.state = {
		    isLoading: true
		  , txtkeyname:''
		  , txtkeytype:''
		  , txtkeyvalue:''
		  , showmodal:this.props.showModal
		  , msg: this.props.msg
		  , isvalidatingsave:false
		  , origSender:[]
		  , selectedSender:{}
		  , origsenderset:false
		  , addkeys:[]
		  , delkeys:[]
		  
		  
		};
		
		this.spfxd = new spfxdata();
		
		
     	this.closeModal = this.closeModal.bind(this);
     


	this.oncomponentmounted = async (susr)=> {
        
        this.setState( { domainKeys : this.props.selectedSender.dkimkeys } );
        
        this.spfxd.setuser(susr);
        
		
	} 
	    
     	
}
//-----------------------------------------------------------------------------------------------------------------------------
static getDerivedStateFromProps(nextProps, prevState) {
        if (prevState.selectedSender !== nextProps.selectedSender) {
          return { selectedSender: nextProps.selectedSender };
        }
    
        return null;
    }

 	
   
//-----------------------------------------------------------------------------------------------------------------------------
handleChange = event => {
    this.setState({
      [event.target.id]: event.target.value
    });
  }
//-----------------------------------------------------------------------------------------------------------------------------
closeModal(){
	
		
		this.setState({
		    txtkeyname:''
		  , txtkeytype:''
		  , txtkeyvalue:''
		  , msg:''
		  , domainkeys:[]
		  , origsenderset:false
		  , origSender:{}
		})
		
		if(this.state.origsenderset){
			this.props.oncancel(this.state.origSender);
		}else{
			this.props.oncancel(this.props.selectedSender)
		}
		
		this.props.onclose();
}

//-----------------------------------------------------------------------------------------------------------------------------
  handleTxtFieldChange = event => {
    this.setState({
      [event.target.id]: event.target.value
    });
  }
//-----------------------------------------------------------------------------------------------------------------------------    
handleSelectChange = (event,id) =>{

	var st = {};
	st[id]=event.value;
	this.setState(st);
}  
//-----------------------------------------------------------------------------------------------------------------------------

handleAddDKIM = async (event) =>{
    event.preventDefault();
	
	
	var keyinfo = {};
		keyinfo.keyname = this.state.txtkeyname;
		keyinfo.type = this.state.txtkeytype;
		keyinfo.value= this.state.txtkeyvalue;
	
	//var addkeys = this.state.addkeys;
	//	addkeys.push(keyinfo);
		
		
	if(!this.state.origsenderset){
		const origSender = JSON.parse(JSON.stringify(this.state.selectedSender));
		
		var seldkeys = this.state.selectedSender.dkimkeys
		
		if(!seldkeys)
			seldkeys=[];
		
		seldkeys.push(keyinfo);
		
		this.setState( { origSender : origSender, origsenderset:true, selectedSender :{ dkimkeys : seldkeys} });
		
		
	}else{
	var seldkeys = this.state.selectedSender.dkimkeys
		if(!seldkeys)
			seldkeys=[];
			
		seldkeys.push(keyinfo);
	
		this.setState({ selectedSender :{ dkimkeys : seldkeys}});
	
	}
   /* var pparams = {};
            
	    pparams.spfx = {
	                 spfxio_id: this.props.selectedDomain.spfxio_id  //spfxio hosted domain id
	               , domain: this.props.selectedDomain.domain
	               , authsenderid: this.props.selectedSender.authsenderid
	               , keyname:keyinfo.keyname
	               , keytype:keyinfo.type
	               , keyvalue:keyinfo.value
	        };
	        
	        this.spfxd.setuser(this.props.user);
	        
	        try{
	        

    			var res = await this.spfxd.adddomainkey(pparams);
    				this.setState({msg:res.msg});
    				this.props.onkeyschanged(seldkeys);
    			}catch(err){
    				this.setState({msg : JSON.stringify(err)});
    			}
	        
	*/
	
}

//-----------------------------------------------------------------------------------------------------------------------------
 handleSaveKeys = async (event) =>{
    event.preventDefault();
	
	var iserror=false;
	this.setState({ isvalidatingsave: true });
                                            
                                            
		var pparams = {};
        pparams.spfx = {
	                 spfxio_id: this.props.selectedDomain.spfxio_id  //spfxio hosted domain id
	               , domain: this.props.selectedDomain.domain
	               , authsenderid: this.props.selectedSender.authsenderid
	               , dkimkeys : this.state.selectedSender.dkimkeys
	               /*, keyname:keyinfo.keyname
	               , keytype:keyinfo.type
	               , keyvalue:keyinfo.value*/
	        };
	        //alert(JSON.stringify(pparams.spfx));
	        
	        try{
	        	var res = await this.spfxd.upsertdomainkeys(pparams);
	        	switch(res.code){
	        		case 200:
	        			this.setState({msg:"Domain keys saved successfully."});
	        		break;
	        		default:
	        			this.setState({msg:"unknown response code: " + res.code});
	        		;
	        	}
	        	
	        }catch(err){
	        	
	        	this.setState({msg : JSON.stringify(err)});
	        	iserror=true;
	        }
	        
	        //send to service, which does the delta between implemented/not implemented, 
	        //- r53 and DB upsert to captures modifications and unecessary records are removed
	        //alert('TO UPSERT' + JSON.stringify(this.state.selectedSender.dkimkeys));
	        
	        //this.spfxd.setuser(this.props.user);
	        
	        /*try{
	        

    			var res = await this.spfxd.adddomainkey(pparams);
    				this.setState({msg:res.msg});
    				this.props.onkeyschanged(seldkeys);
    			}catch(err){
    				this.setState({msg : JSON.stringify(err)});
    			}
    		*/
	        
	this.setState({ origsenderset:false, isvalidatingsave: false });
	if(!iserror){
		//this.props.onclose();
	}

}                                             
//-----------------------------------------------------------------------------------------------------------------------------
//-----------------------------------------------------------------------------------------------------------------------------
handleDeleteRow = async(k, e)=>{
 	
 	if(!this.state.origsenderset){
		const origSender = JSON.parse(JSON.stringify(this.state.selectedSender));
		
	
	 	var delkeys = this.state.delkeys;
			
		
	 	var crrdkeys = this.state.selectedSender.dkimkeys;//.domainKeys;
	 	//this.props.selectedSender.dkimkeys.splice(k,1);
	 	delkeys.push(crrdkeys.splice(crrdkeys.indexOf(k),1));
	 	this.setState({ origSender : origSender, origsenderset:true, delkeys : delkeys });
 	}else{
 	
	 	var delkeys = this.state.delkeys;
			
		
	 	var crrdkeys = this.state.selectedSender.dkimkeys;//.domainKeys;
	 	//this.props.selectedSender.dkimkeys.splice(k,1);
	 	delkeys.push(crrdkeys.splice(crrdkeys.indexOf(k),1));
	 	this.setState({ delkeys : delkeys });
 	}
 	
 	
 	
 }
//----------------------------------------------------------------------------------------------------------------------------- 
 onafteropen(){
	
	 
	 
 }
//-----------------------------------------------------------------------------------------------------------------------------

  renderDkimKeys(keys){
  		
  		
		return [{}].concat(keys).map(
			    (k, i) =>
			      i !== 0 && k
			        ? 
			            <ListGroupItem>
			             <div class="form-group">
			             	<div class="pull-right"><button value={k} className="btn btn-warning" onClick={(e) => this.handleDeleteRow(k, e)}>Remove</button></div>
			             	<label class="control-label mb-10">Key:</label>
			             	<div class="dkimkeyname"> {k.keyname} </div>
			             	<label class="control-label mb-10">Type:</label>
			                <div class="dkimkeytype"> {k.type} </div>
			                <label class="control-label mb-10">Value:</label>
			                <div class="dkimkeyvalue"> {k.value} </div>
			            </div>
			            </ListGroupItem>
			          
			        :   <Fragment />
			          
			  );
  
  }


render(){

	return (
		
			<Modal id="responsive-modal"
            		dialogClassName="addnewdomainmodal"
                    className="modal fade"
                    show={this.props.showModal}
                    onHide={this.closeModal}
                    
                    animation={true}
                    bsSize="small">
                    <Modal.Header closeButton className="modal-header">
                        <Modal.Title className="modal-title">Domain Keys</Modal.Title>
                    </Modal.Header>
                    <Modal.Body  className="modal-body">
                            <div id="modalmsg">
                                { this.state.msg }
                            </div>
                            <form>
                               
                                <div className="form-group">
                                    <label for="recipient-name" className="control-label mb-10">Key Name:</label>
                                    <input type="text" className="form-control" id="txtkeyname" onChange={this.handleTxtFieldChange} value={this.state.txtkeyname}  />
                                </div>
                                <div className="form-group">
                                    <label for="recipient-name" className="control-label mb-10">Type:</label>
                                    <Select id="txtkeytype" onChange={(event)=>{ this.handleSelectChange(event,'txtkeytype') } } options={[{value:'TXT',label:'TXT'},{value:'CNAME',label:'CNAME'}]} />
                                </div>
                                <div className="form-group">
                                    <label for="recipient-name" className="control-label mb-10">Value:</label>
                                    <input type="text" className="form-control" id="txtkeyvalue" onChange={this.handleTxtFieldChange} value={this.state.txtkeyvalue}  />
                                </div>
                                    
                                    
                                     <LoaderButton
                                            block
                                            type="button"
                                            text="Add Key"
                                            loadingText="validating..."
                                            isLoading={this.state.isvalidatinghostedomain}
                                            className="btn btn-warning"
                                            onClick={(e) => this.handleAddDKIM(e)} 
                                            
                                        />
                                    
                            </form>
                            
                             <div className="dkimkeycontainer col-md-12 mt-15">
                                  <ListGroup className="">
                                      { this.renderDkimKeys(this.state.selectedSender.dkimkeys) } 
                                  </ListGroup>
                            </div>
                            
                    </Modal.Body>

                    <Modal.Footer>
                                   
                                        
                                      <LoaderButton
                                            block
                                            type="button"
                                            text="Save changes"
                                            loadingText="validating..."
                                            isLoading={this.state.isvalidatingsave}
                                            className="btn btn-primary"
                                            onClick={(e) => this.handleSaveKeys(e)} 
                                            
                                        />
                                     <LoaderButton
                                            block
                                            type="button"
                                            text="Cancel"
                                            data-dismiss="modal"
                                            className="btn btn-default"
                                            onClick={this.closeModal}
                                        />
                    </Modal.Footer>

                </Modal>
		  
		);


}

}