import React, { Fragment } from "react";
import { Auth, API } from "aws-amplify";

import "./quicksight.css";

import LeftNav from "../components/LeftNav"
import TopNav from "../components/TopNav"
import RightNav from "../components/RightNav"
import Footer from "../components/Footer"
import BaseComponent from "../classes/BaseComponent"



export default class quicksight extends BaseComponent {
  constructor(props) {
    super(props);
    
    this.state = {
      isLoading: null
      , redirecturl: ""
      , msg:""
   };
    
    this.oncomponentmounted = (susr)=> {
        //this.setState({"msg":"page loaded"});
        this.prepareredirect(susr);
    
    };
	
	
  }

    
    async prepareredirect(susr){
        try{
        var retval = await this.geturl(susr.idToken.jwtToken,'https://us-east-1.quicksight.aws.amazon.com/sn/start');
            this.setState({"redirecturl":retval});
            //var win = window.open(retval, '_blank');
            //win.focus();
        }catch(e){
            this.setState("error encountered during redirect.  aborting.");
        }
    }
 
  
    geturl(jwtToken, redirecturl){
        
        return API.put('getsigninurl', '/getsigninurl', { body:{ "jwtToken" : jwtToken, "redirect_url":redirecturl},headers:{}});
    }
    
   
	
  
  render(){
       
	
    
	  return (
                <Fragment>
				<TopNav />
     			<LeftNav />
				<RightNav />
                {/*<!-- Main Content -->*/}
                <div class="page-wrapper">
					<div class="container-fluid">
						
						{/*<!-- Title -->*/}
						<div class="row heading-bg">
							<div class="col-lg-3 col-md-4 col-sm-4 col-xs-12">
								<h5 class="txt-dark">Reporting</h5>
							</div>
						</div>
                        {/*<!--/ Title -->*/}
                        <div class="row"></div>
                        	<div class="col-sm-12">
							<div class="panel panel-default card-view">
								<div class="panel-heading">
									<div class="pull-left">
										<h6 class="panel-title txt-dark">Reporting</h6>
									</div>
									<div class="clearfix"></div>
								</div>
								<div class="panel-wrapper collapse in">
									<div class="panel-body">
										<div class="form-wrap">
                                            <div id="msg">
                                            You are being redirected to the reporting platform.  If you are not redirected, use the link below to access.
                                            
                                            <a href={this.state.redirecturl} target="_blank" rel="noopener noreferrer">Reports</a> 
                                            </div>
                                            <div>
                                                    {this.state.msg}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            
                            
                        </div>

                         <Footer />
					</div>
               
				{/*<!-- /Main Content -->*/}
		</div>
		</Fragment>
	  );
	  
  }
  
  
}