import { Auth } from "aws-amplify";
import React, { Component } from "react";
import BaseComponent from "../classes/BaseComponent"


import NavBar from "./NavBar";

import "../dist/css/style.css";

export default class Home extends BaseComponent {
	constructor(props) {
    super(props);
   

    //this.preventsessioncopy();
    this.allowsessioncopy=()=>{
    	return false;
    }
    
    
    this.state = {
    		
    		
        isLoading: true
      , allowsessioncopy:false
      , notes: []
      
      
      
    };
	
    this.oncomponentmounted=(susr)=>{
    	localStorage.setItem('clearSessionStorage', 'foobar');
	    localStorage.removeItem('clearSessionStorage', 'foobar');
    	this.signout();
    }
	
    
	
  }
  
  async signout(){
    Auth._storage.setItem("ActiveSession",false,true);
    await Auth.signOut();    
    Auth._storage.clear();
	this.setState({ userHasAuthenticated:false });
	this.props.history.push("/a/signin");
  }
  
   render() {
    return (
      <div className="lander">
        <NavBar />
      </div>
    );
  }
  
  
}